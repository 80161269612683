<template>
  <div class="tab-contents-wrap">
    <template v-if="statusType < 6">
      <!-- Step -->
      <div class="step-wrap">
        <ol class="step">
          <li :class="{ on: statusType === 1}">{{$t('content.event.EventReservation.txt76')}}</li>
          <!-- 해당 step에 on 클래스 추가 -->
          <li :class="{ on: statusType === 2}">{{$t('content.event.EventReservation.txt77')}}</li>
          <li :class="{ on: statusType === 3}">{{$t('content.event.EventReservation.txt78')}}</li>
          <li :class="{ on: statusType === 4}">{{$t('content.event.MobileEventReservation.txt14')}}</li>
          <li :class="{ on: statusType === 5}">{{$t('content.event.MobileEventReservation.txt15')}}</li>
        </ol>
      </div>
      <!-- Step -->
      <!-- Form -->
      <div class="form-container">
        <ValidationObserver ref="form">
          <!-- step1 -->
          <template v-if="statusType === 1">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt79')}}</legend>
                <!-- <div class="align-right" v-html="$t('content.event.EventReservation.txt80')">
                </div> -->
                <ul>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt81')"
                      v-slot="{ errors }"
                    >
                      <BaseInput
                        v-model="eventName"
                        :label="$t('content.event.EventReservation.txt81')"
                        :placeholder="$t('content.event.EventReservation.txt82')"
                        :class="{'error':errors[0]}"
                        maxlength="20"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt83')}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt84')"
                      v-slot="{ errors }"
                    >
                      <BaseTextarea
                        :label="$t('content.event.EventReservation.txt84')"
                        v-model="eventDesc"
                        :placeholder="$t('content.event.EventReservation.txt85')"
                        size="large"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt86')"
                      v-slot="{ errors }"
                    >
                      <BaseTextarea
                        :label="$t('content.event.EventReservation.txt86')"
                        v-model="sponsor"
                        :placeholder="$t('content.event.EventReservation.txt87')"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <BaseTextarea
                      :label="$t('content.event.EventReservation.txt88')"
                      v-model="eventParticipantIntroduce"
                      :placeholder="$t('content.event.EventReservation.txt89')"
                    />
                  </li>
                  <!-- 예상 참여자수 --->
                  <li class="form-row">
                    <ValidationProvider
                    :rules="{ required: true }"
                    :name="$t('content.event.EventReservation.txt90')"
                    v-slot="{ errors }"
                  >
                      <BaseInput
                        type="number"
                        :label="$t('content.event.EventReservation.txt90')"
                        v-model="expectParticipantCount"
                        :placeholder="$t('content.event.EventReservation.txt91')"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step1 -->
          <!-- step2 시설 이용 선택-->
          <template v-else-if="statusType === 2">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt92')}}</legend>
                <!-- <div class="align-right" v-html="$t('content.event.EventReservation.txt80')">
                </div> -->
                <ul>
                  <!-- 모바일 시설 이용 선택 내용 나오게 수정-->
<!--                  <template-->
<!--                    v-if="!(userInfo.member_role === 'MB202'|| !!userInfo.external_company_no)-->
<!--                  || rooms.includes('6')"-->
<!--                  >-->
                    <template>
                    <li class="form-row" v-if="isUseMic">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt93')" />
                      <BaseRadioButton name="useMic" v-model="useMic" :value=true>
                        {{$t('content.event.EventReservation.txt94')}}
                      </BaseRadioButton>
                      <BaseRadioButton name="useMic" v-model="useMic" :value=false>
                        {{$t('content.event.EventReservation.txt95')}}
                      </BaseRadioButton>
                    </li>
                    <!-- 좌석 셋팅-->
                    <li class="form-row tline" v-if="!isDreamLounge">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt96')" />
                      <BaseRadioButton
                        name="codeSeatSetting"
                        v-model="codeSeatSetting"
                        :value="3551"
                        @change="otherSeatSetting=''"
                        :disabled="!rooms.includes('6')"
                      >{{$t('content.event.EventReservation.txt97')}}</BaseRadioButton>
                      <BaseRadioButton
                        name="codeSeatSetting"
                        v-model="codeSeatSetting"
                        :value="3552"
                        @change="otherSeatSetting=''"
                        :disabled="!rooms.includes('6')"
                      >{{$t('content.event.EventReservation.txt98')}}</BaseRadioButton>
                      <BaseRadioButton
                        name="codeSeatSetting"
                        v-model="codeSeatSetting"
                        :value="3553"
                        @change="otherSeatSetting=''"
                      >{{$t('content.event.EventReservation.txt42')}}</BaseRadioButton>
                      <!-- 좌석 기타 내용 입력-->
                      <ValidationProvider
                        :rules="{ required : codeSeatSetting === 3553 ?  true : false }"
                        :name="$t('content.event.EventReservation.txt99')"
                        v-slot="{ errors }"
                      >
                        <BaseInputElement
                          v-model="otherSeatSetting"
                          :placeholder="$t('content.event.EventReservation.txt99')"
                        />
                        <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                        <span class="input-desc">{{$t('content.event.EventReservation.txt100')}}</span>
                      </ValidationProvider>
                    </li>
                    <li class="form-row tline" v-if="!isDreamLounge">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt135')" />
                      <div class="checkbox_group col3">
                        <BaseCheckbox v-model="useTool[0]"
                          trueValue="3500">
                          HDMI
                        </BaseCheckbox>
                        <BaseCheckbox v-model="useTool[1]"
                          trueValue="3501">
                          {{$t('content.event.EventReservation.txt130')}}
                        </BaseCheckbox>
                        <BaseCheckbox v-model="useTool[2]"
                          trueValue="3502"
                          >
                          {{$t('content.event.EventReservation.txt131')}}
                        </BaseCheckbox>
                        <BaseCheckbox v-model="useTool[3]"
                          trueValue="3503"
                          >
                          {{$t('content.event.EventReservation.txt132')}}
                        </BaseCheckbox>
                        <BaseCheckbox v-model="useTool[4]"
                          trueValue="3504">
                          {{$t('content.event.EventReservation.txt133')}}
                        </BaseCheckbox>
                        <BaseCheckbox v-model="useTool[5]"
                          trueValue="3505">
                          {{$t('content.event.EventReservation.txt134')}}
                        </BaseCheckbox>
                      </div>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt136')}}</span>
                    </li>
                    <li class="form-row tline">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt101')" />
                      <BaseRadioButton name="isBringCatering" v-model="isBringCatering" :value=false>
                        {{$t('content.event.EventReservation.txt102')}}
                      </BaseRadioButton>
                      <BaseRadioButton name="isBringCatering" v-model="isBringCatering" :value=true>
                        {{$t('content.event.EventReservation.txt103')}}
                      </BaseRadioButton>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt104')}}</span>
                    </li>
                  </template>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step2 -->
          <!-- step3 -->
          <template v-else-if="statusType === 3">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt106')}}</legend>
                <ul>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true, phone: true }"
                      :name="$t('content.event.EventReservation.txt107')"
                      v-slot="{ errors }"
                    >
                      <TelInput
                        v-model="mobileNumber"
                        :label="$t('content.event.EventReservation.txt107')"
                        :placeholder="$t('content.event.EventReservation.txt108')"
                        :disabled="!enabledInput"
                        :class="{'error':errors[0]}"
                        required
                        maxlength="13"
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <BaseInput
                      v-model="otherInquiry"
                      :label="$t('content.event.EventReservation.txt109')"
                      :placeholder="$t('content.event.EventReservation.txt110')"
                      maxlength="100"
                    />
                    <span class="input-desc">{{$t('content.event.EventReservation.txt111')}}</span>
                  </li>
                  <li class="form-row">
                    <FileInput
                      ref="attachementFile"
                      :id="'attachFile'"
                      :label="$t('content.event.EventReservation.txt112')"
                      v-model="fileList"
                      :placeholder="$t('content.event.EventReservation.txt112')"
                      :buttonLabel="$t('content.event.EventReservation.txt112_1')"
                      accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"
                      @change="(file) => selectFile(file)"
                      @remove="removeFile"
                    />
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step3 -->
          <!-- step4 내역확인-->
          <template v-else-if="statusType === 4">
            <form>
              <fieldset>
                <legend>확인</legend>
                <ul class="info-list">
                  <li class="form-row">
                    <div class="input-title">{{$t('content.event.MobileEventReservation.txt16')}}</div>
                    <div class="text-container">
                      <template
                        v-for="(code, index) in rooms">
                        {{$t(`commonCode.${code}`)}}{{(rooms.length > index + 1) ? ' & ' :''}}
                      </template>
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.MobileEventReservation.txt17')}}</div>
                    <div class="text-container">
                      {{`${startTime.format('YYYY.M.DD(ddd) HH:mm')} - ${endTime.format('HH:mm')}`}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt81')}}</div>
                    <div class="text-container">
                      {{eventName}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt84')}}</div>
                    <div class="text-container">
                      {{eventDesc}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt86')}}</div>
                    <div class="text-container">
                      {{sponsor}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt88')}}</div>
                    <div class="text-container">
                      {{eventParticipantIntroduce}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt90')}}</div>
                    <div class="text-container">
                      {{expectParticipantCount}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt92')}}</div>
                    <div class="text-container">
                      <ul>
                        <li v-if="isUseMic">{{ micTxt }}</li>
                        <li>{{ cateringTxt }}</li>
                        <li v-if="!isDreamLounge" v-html="seatSettingTxt"></li>
                        <li v-if="!isDreamLounge">
                          <span class="toolname" v-if="useTool[0]">HDMI<span>,</span></span>
                          <span class="toolname" v-if="useTool[1]">{{$t('content.event.EventReservation.txt130')}}<span>,</span></span>
                          <span class="toolname" v-if="useTool[2]">{{$t('content.event.EventReservation.txt131')}}<span>,</span></span>
                          <span class="toolname" v-if="useTool[3]">{{$t('content.event.EventReservation.txt132')}}<span>,</span></span>
                          <span class="toolname" v-if="useTool[4]">{{$t('content.event.EventReservation.txt133')}}<span>,</span></span>
                          <span class="toolname" v-if="useTool[5]">{{$t('content.event.EventReservation.txt134')}}<span>,</span></span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt107')}}</div>
                    <div class="text-container">
                      {{mobileNumber}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt109')}}</div>
                    <div class="text-container">
                      {{otherInquiry}}
                    </div>
                  </li>
                  <li class="form-row tline">
                    <div class="input-title">{{$t('content.event.EventReservation.txt112')}}</div>
                    <div class="text-container">
                      <template v-for="(file, index) in fileList">
                        <p>{{ file.name }}</p>
                      </template>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step4 -->
          <!-- step5 약관-->
          <template v-else-if="statusType === 5">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt92')}}</legend>
                <!-- <div class="align-right" v-html="$t('content.event.EventReservation.txt80')">
                </div> -->
                <ul>
                  <li class="form-row">
                    <div class="terms-area"  @click="openPopup('facilityParams')">
                      <div class="check_link">
                        <BaseCheckbox v-model="spaceAgreement" required>
                        {{$t('content.event.EventReservation.txt105')}}
                        </BaseCheckbox>
                        <a href="#" class="btn_arrow"><span class="blind">more</span></a>
                      </div>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
        </ValidationObserver>
      </div>
      <!-- // Form -->
      <!-- 버튼 -->
      <FixedButton :isFooter="isFooter">
        <template slot="buttons" v-if="statusType === 1">
          <a href="#" @click="goBack" class="btn-ghost back">Back</a>
          <button
            @click="changePage(2)"
            :disabled="eventName.length === 0 || eventDesc.length === 0
          || sponsor.length === 0 || expectParticipantCount.length === 0"
            type="button"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template slot="buttons" v-else-if="statusType === 2">
          <button
            @click="changePage(1)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="changePage(3)"
            type="button"
            :disabled="!codeSeatSetting || (!isDreamLounge && codeSeatSetting === 3553 && !otherSeatSetting)"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template slot="buttons" v-else-if="statusType === 3">
          <button
            @click="changePage(2)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="changePage(4)"
            type="button"
            :disabled="mobileNumber.length === 0"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template slot="buttons" v-else-if="statusType === 4">
          <button
            @click="changePage(3)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="changePage(5)"
            type="button"
            :disabled="mobileNumber.length === 0"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template slot="buttons" v-else-if="statusType === 5">
          <button
            @click="changePage(4)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="applicate"
            :disabled="!spaceAgreement"
            type="button"
            class="btn-basic"
          >{{$t('content.common.button.btn22')}}</button>
        </template>
      </FixedButton>
      <!-- // 버튼 -->
    </template>

    <template v-if="statusType === 6">
      <div class="reservation-result-wrap">
        <div class="reservation-result" data-aos="flip-right">
          <dl class="completed">
            <dt>{{$t('content.event.EventReservation.txt119')}}</dt>
            <dd class="desc" v-html="$t('content.event.EventReservation.txt120')">

            </dd>
          </dl>
        </div>
      </div>
      <div class="btn_bottom_wrap">
        <button @click="goHistory" class="btn-basic">{{$t('content.common.button.btn04')}}</button>
      </div>
    </template>
    <template v-if="statusType === 7">
      <!-- 대관 신청 결과 -->
      <div class="reservation-result-wrap">
        <div class="reservation-result" data-aos="flip-right">
          <dl class="failed">
            <dt>{{$t('content.event.EventReservation.txt121')}}</dt>
            <dd class="desc" v-html="$t('content.event.EventReservation.txt122')"></dd>
          </dl>
        </div>
      </div>
      <!-- // 대관 신청 결과 -->
      <div class="btn_bottom_wrap">
        <router-link :to="{name:'ReservationApplication'}" type="button" class="btn-basic">
          {{$t('content.event.EventReservation.txt123')}}
        </router-link>
      </div>
    </template>
    <FacilityAgreePopup
      :params="facilityParams"
      @facilityAgree="facilityAgree"
    />
  </div>
</template>

<script>
import { APIs, FileCategory } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import FileInput from '@/components/base/FileInputMultiple.vue';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FixedButton from '@/components/base/FixedButton.vue';
import FacilityAgreePopup from '@/components/popup/FacilityAgreePopup.vue';

export default {
  name: 'ReservationApply',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseLabelElement,
    BaseInputElement,
    BaseInput,
    BaseTextarea,
    BaseRadioButton,
    BaseCheckbox,
    TelInput,
    FileInput,
    ValidationObserver,
    ValidationProvider,
    FixedButton,
    FacilityAgreePopup,
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    startTime: {
      type: Object,
      required: true,
    },
    endTime: {
      type: Object,
      required: true,
    },
    isReservation: {
      type: Boolean,
      required: false,
    },
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      // ui: helperUtils.UI,
      statusType: 1,
      fileList: [],
      userInfo: undefined,
      mobileNumber: '',
      enabledInput: false,
      spaceAgreement: undefined,
      privatedAgreement: undefined,
      memberAddMobileYn: false,
      eventName: '',
      eventDesc: '',
      sponsor: '',
      eventParticipantIntroduce: '',
      expectParticipantCount: '',
      useMic: false,
      codeSeatSetting: 3551,
      otherSeatSetting: '',
      isBringCatering: false,
      otherInquiry: '',
      attachments: '',
      isMobile: false,
      codeDivisionCenter: 'CT101',
      useTool: [],
      micTxt: '',
      cateringTxt: '',
      seatSettingTxt: '',
      useToolTxt: '',
      facilityParams: {
        open: false,
      },
      isUseMic: true,
      isDreamLounge: false
    };
  },
  computed: {
    changeStep() {
      return this.$store.getters.getPagestep;
    },
  },
  watch: {
    changeStep(val) {
      if (val >= 1 && val <= 5) {
         this.statusType = val;
      }
    },
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    const mobileNumber0 = this.userInfo.mobile_number0 || '';
    const mobileNumber1 = this.userInfo.mobile_number1 || '';
    const mobileNumber2 = this.userInfo.mobile_number2 || '';
    this.mobileNumber = `${mobileNumber0}${mobileNumber1}${mobileNumber2}`;
    this.enabledInput = this.mobileNumber === '';
    if (this.$route.params) {
      this.isUseMic = this.$route.params.useMic
      this.isDreamLounge = this.$route.params.isDreamLounge
    }
  },
  mounted() {
    if (this.isReservation) {
      this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg2'), () => {
        this.$emit('input', 1);
      });
      return;
    }
    if (!this.rooms || !this.startTime || !this.endTime) {
      this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg3'), () => {
        this.$emit('input', 1);
      });
    }
    // 메일홀이 아닐 경우 좌석세팅 기타로 설정
    if (!this.rooms.includes('6')) {
      this.codeSeatSetting = 3553;
    }
    // footer 유무 설정
    this.setFooter(false);
  },
  methods: {
    facilityAgree(state) {
      this.spaceAgreement = state;
    },
    openPopup(name) {
        this[name].open = true;
    },
    changePage(index) {
        window.scrollTo(0, 0);
        this.statusType = index;
        this.$store.commit('setPagestep', index);
        if (this.statusType === 4) { // 입력 내용 확인
          // use a microphone, Disable microphone
          this.micTxt = this.useMic === true ? this.$t('content.event.EventReservation.txt138') : this.$t('content.event.EventReservation.txt139');
          console.log('useMic: ', this.useMic);
          this.cateringTxt = this.isBringCatering === true ? this.$t('content.event.EventReservation.txt101') : this.$t('content.event.EventReservation.txt137');
          console.log('isBringCatering: ', this.isBringCatering);
          let returnValue = '';
          if (this.codeSeatSetting === 3551) {
            returnValue = this.$t('commonCode.CT301');
          } else if (this.codeSeatSetting === 3552) {
            returnValue = this.$t('commonCode.CT302');
          } else if (this.codeSeatSetting === 3553) {
            returnValue = this.$t('content.event.EventReservation.txt42');
          }
          this.seatSettingTxt = `${returnValue}<br/>${this.otherSeatSetting}`;

          let useToolString = '';
          if (this.useTool) {
            this.useTool.forEach((v) => {
              if (!v) return;
              useToolString += `${v}, `;
            });
          }
          this.useToolTxt = useToolString.slice(0, -2);
        }
    },
    Rearrangement(allRoom, room) {
      let type;
      switch (room) {
        case '6':
          type = 'HALL';
          break;
        case '7':
          type = 'A';
          break;
        case '8':
          type = 'B';
          break;
        case '9':
          type = 'C';
          break;
        case '10':
        case 'CT207':
          type = 'D';
          break;
        case '11':
        case 'CT208':
          type = 'E';
          break;
        default:
      }

      let idx = -1;
      if (type === 'D' && allRoom.includes('E')) {
        idx = allRoom.indexOf('E');
      } else if (type === 'E' && allRoom.includes('D')) {
        idx = allRoom.indexOf('D');
      }
      if (idx > -1) {
        allRoom.splice(idx, 1, 'DE');
      }
      return [...allRoom, type];
    },
    async applicate() {
      const borrowPlaceInfo = [];
      let point = 0;
      const hours = this.endTime.diff(this.startTime, 'hour');

      this.rooms.reduce(this.Rearrangement, []).forEach((v) => {
        if (this.userInfo.member_role === 'MB202') {
          if (v === 'HALL') {
            point += 240000 * hours;
          } else if (v === 'D' || v === 'E') {
            point += 80000 * hours;
          } else if (v === 'DE') {
            point += 240000 * hours;
          } else {
            point += 40000 * hours;
          }
        }
      });
      this.rooms.reduce(this.Rearrangement, []);

      let reservedPlaceIds = [];
      this.rooms.forEach((v) => {
        borrowPlaceInfo.push(v);
      });
      
      const params = {
        memberId: this.userInfo.id,
        eventName: this.eventName,
        eventDesc: this.eventDesc,
        sponsor: this.sponsor,
        eventParticipantIntroduce: this.eventParticipantIntroduce,
        expectParticipantCount: this.expectParticipantCount,
        useMic: this.useMic,
        codeSeatSetting: this.codeSeatSetting,
        otherSeatSetting: this.otherSeatSetting,
        isBringCatering: this.isBringCatering,
        otherInquiry: this.otherInquiry,
        phone: this.mobileNumber,
        // mobile_number0: mobileNumber[0] || '',
        // mobile_number1: mobileNumber[1] || '',
        // mobile_number2: mobileNumber[2] || '',
        memberAddMobileYn: this.memberAddMobileYn,
        startTime: this.$moment(this.startTime).format('YYYY.MM.DD HH:mm'),
        endTime: this.$moment(this.endTime).format('YYYY.MM.DD HH:mm'),
        reservedPlaceIds: borrowPlaceInfo.join(','),
        // point,
        toolIds: this.useTool.filter(v=>!!v).join(','),
        fileList: this.fileList
      };

      const res = await this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_APPLY,
        data: params,
      });

      if (res.data.result) {
        this.statusType = 6;
        this.$emit('reservation');
      } else {
        this.statusType = 7;
        this.$store.commit('setPagestep', 7);
      }
    },
    async selectFile(file) {
      if (file) {
        if (file.size > 10485760) {
          this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg4'));
          return;
        }
        // 파일 형식 체크
        const res = await this.uploadFile(
          FileCategory.EVENTHALL,
          2, // attachement
          0,
          file,
        )
        
        if (res.result) {
          this.fileList.push({
            id: res.data.id,
            name: file.name,
            fileData: file
          })
          this.$refs.attachementFile.onFileAdd(file)
        } else if (res.code == '202') {
          this.showAlert('파일 형식은 ".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"으로 가능합니다');
        }
      }
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },
    goHistory() {
      // this.$router.replace({ name: 'ReservationHistory' });
      this.$emit('input', 1);
    },
    goBack(event) {
      event.preventDefault();
      this.showConfirm(this.$t('content.event.EventReservation.alertMsg.msg5'), () => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style scoped>
  .toolname:last-child > span {
    display: none;
  }
</style>
