<template>
  <div class="contents application" id="contents">
    <section class="title-section">
      <div class="title-wrap">
        <h2 class="title">DREAMPLUS SPACE</h2>
      </div>
    </section>
    <div class="tab-section">
      <div class="tab-inner">
        <ul>
          <li><a href="#" class="active">Gangnam</a></li>
        </ul>
      </div>
    </div>
    <div class="inner-lg">
      <!-- Multi Room Section -->
      <div class="reservation-tab">
        <div class="tab-wrap title-tab">
          <BaseTab
            v-model="roomTabValue"
            v-bind:list="room"
            tabtype="small-tab"
            @input="roomTabCallback"
            >
            <template v-slot="Scope">
              <li class="tab-item" :class="Scope.paramClass">{{Scope.title}}</li>
            </template>
          </BaseTab>
        </div>
        <BaseSlider
          width='1400'
          ref="roomSlider"
          :autoplay='false'
          :pagination="false"
          :navigation="false"
          :list="roomList"
          :simulateTouch="false"
          @change="onChangeRoom">
          <template v-slot="{ item }">
            <div class="event-reservation-wrap multi-room">
              <!-- <img :src="requireAssetsImage(item)" /> -->
              <h6 class="room-title">
                {{ item.floor }}F<span class="bar"></span>{{ item.title }}
              </h6>
              <div v-if="item.floor==='B1'&&item.title==='MULTI ROOM B1F'" class="multi-room">
                <!-- Tab -->
                <BaseTab
                  v-model="tabValue"
                  v-bind:list="multiRoom"
                  tabtype="small-tab"
                  @input="tabCallback"
                  >
                  <template v-slot="Scope">
                    <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
                  </template>
                </BaseTab>
                <!-- // Tab -->
                <!-- Multi Room -->
                <div class="multi-room-area">
                  <BaseSlider class="s2"
                    ref="multiRoomSlider"
                    width='1400'
                    :autoplay='false'
                    :list="multiRoomList"
                    :pagination="false"
                    :navigation="false"
                    @change="onChangeMultiRoom"
                    >
                    <template v-slot="{ item }">
                      <div class="event-reservation-wrap multi-room">
                        <!-- 이미지 영역-->
                        <div class="image-area" data-aos="fade-right">
                          <img :src="item.img" :alt="`${item.title} 이미지`" />
                        </div>
                        <!-- // 이미지 영역 -->
                        <!-- 이용 안내 -->
                        <!-- <div v-html="item.content">
                        </div> -->
                        <div class='info-area'>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt197')}}</dt>
                              <dd>
                                <div class="info-price">
                                  <strong>{{$t('content.event.EventReservation.txt198')}}</strong> {{ item.price }}
                                </div>
                                <div>
                                  <div class="info-person">
                                    <strong>{{$t('content.event.EventReservation.txt184')}}</strong> {{item.person}}{{$t('content.event.EventReservation.txt184_2')}}
                                  </div>
                                  <ul class="list-dot" v-html="item.info">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt185')}}</dt>
                              <dd>
                                <div class="info-time">
                                  <div>
                                    <strong>{{$t('content.event.EventReservation.txt186')}}</strong> 09:00 ~ 21:00
                                  </div>
                                  <div>
                                    <strong>{{$t('content.event.EventReservation.txt187')}}</strong> 09:30 ~ 18:00
                                  </div>
                                </div>
                                <div>
                                  <ul class="list-dot" v-html="item.timeDesc">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt v-html="$t('content.event.EventReservation.txt199')"></dt>
                              <dd v-html="item.facility">
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row'>
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt200')}}</dt>
                              <dd>
                                <img :src="item.map"  />
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <!-- // 이용 안내 -->
                      </div>
                    </template>
                  </BaseSlider>
                  <!-- // Swiper -->
                </div>
              </div>
              <div v-else-if="item.floor==='5'&&item.title==='EVENT HALL'">
                <!-- Tab -->
                <BaseTab
                  v-model="eventTabValue"
                  v-bind:list="eventHall"
                  tabtype="small-tab"
                  @input="eventTabCallback"
                  >
                  <template v-slot="Scope">
                    <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
                  </template>
                </BaseTab>
                <!-- // Tab -->
                <!-- EVENT HALL -->
                <div class="multi-room-area">
                  <BaseSlider class="s2"
                    ref="eventSlider"
                    width='1400'
                    :autoplay='false'
                    :list="eventHallList"
                    :pagination="false"
                    :navigation="false"
                    @change="onChangeEventHall"
                    >
                    <template v-slot="{ item }">
                      <div class="event-reservation-wrap multi-room">
                        <!-- 이미지 영역-->
                        <!-- <div class="image-area" data-aos="fade-right">
                          <img :src="item.img" :alt="`${item.title} 이미지`" />
                        </div> -->
                        <div class="image-area">
                          <HallSlider
                            width='1400'
                            :autoplay='true'
                            :pagination="true"
                            :navigation="false"
                            :list="item.img">
                            <template v-slot="{ item }">
                              <img :src="item"  />
                            </template>
                          </HallSlider>
                        </div>
                        <!-- // 이미지 영역 -->
                        <div class='info-area'>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt197')}}</dt>
                              <dd>
                                <div class="info-price">
                                  <strong>{{$t('content.event.EventReservation.txt198')}}</strong> {{ item.price }}
                                </div>
                                <div>
                                  <div class="info-person">
                                    <strong>{{$t('content.event.EventReservation.txt184')}}</strong> {{item.person}}{{$t('content.event.EventReservation.txt184_2')}}
                                  </div>
                                  <ul class="list-dot" v-html="item.info">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt185')}}</dt>
                              <dd>
                                <div class="info-time">
                                  <div>
                                    <strong>{{$t('content.event.EventReservation.txt186')}}</strong> 09:00 ~ 21:00
                                  </div>
                                </div>
                                <div>
                                  <ul class="list-dot" v-html="item.timeDesc">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt v-html="$t('content.event.EventReservation.txt199')"></dt>
                              <dd v-html="item.facility">
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row'>
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt200')}}</dt>
                              <dd>
                                <img :src="item.map"  />
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <!-- // 이용 안내 -->
                      </div>
                    </template>
                  </BaseSlider>
                  <!-- // Swiper -->
                </div>
              </div>
              <div v-else-if="item.floor==='5'&&item.title==='EXECUTIVE ROOM'">
                <!-- Tab -->
                <BaseTab
                  v-model="vipRoomTabValue"
                  v-bind:list="vipRoom"
                  tabtype="small-tab"
                  @input="vipTabCallback"
                  >
                  <template v-slot="Scope">
                    <li class="" :class="Scope.paramClass">{{Scope.title}}</li>
                  </template>
                </BaseTab>
                <!-- // Tab -->
                <!-- Multi Room -->
                <div class="multi-room-area">
                  <BaseSlider class="s2"
                    ref="vipRoomSlider"
                    width='1400'
                    :autoplay='false'
                    :list="vipRoomList"
                    :pagination="false"
                    :navigation="false"
                    @change="onChangeVipRoom"
                    >
                    <template v-slot="{ item }">
                      <div class="event-reservation-wrap multi-room">
                        <!-- 이미지 영역-->
                        <!-- <div class="image-area" data-aos="fade-right">
                          <img :src="item.img" :alt="`${item.title} 이미지`" />
                        </div> -->
                        <div class="image-area">
                          <HallSlider
                            width='1400'
                            :autoplay='true'
                            :pagination="true"
                            :navigation="false"
                            :list="item.img">
                            <template v-slot="{ item }">
                              <img :src="item"  />
                            </template>
                          </HallSlider>
                        </div>
                        <!-- // 이미지 영역 -->
                        <div class='info-area'>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt197')}}</dt>
                              <dd>
                                <div class="info-price">
                                  <strong>{{$t('content.event.EventReservation.txt198')}}</strong> {{ item.price }}
                                </div>
                                <div>
                                  <div class="info-person">
                                    <strong>{{$t('content.event.EventReservation.txt184')}}</strong> {{item.person}}{{$t('content.event.EventReservation.txt184_2')}}
                                  </div>
                                  <ul class="list-dot" v-html="item.info">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt185')}}</dt>
                              <dd>
                                <div class="info-time">
                                  <div>
                                    <strong>{{$t('content.event.EventReservation.txt186')}}</strong> 09:00 ~ 21:00
                                  </div>
                                </div>
                                <div>
                                  <ul class="list-dot" v-html="item.timeDesc">
                                  </ul>
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row' data-aos="fade-up">
                            <dl>
                              <dt v-html="$t('content.event.EventReservation.txt199')"></dt>
                              <dd v-html="item.facility">
                              </dd>
                            </dl>
                          </div>
                          <div class='info-row'>
                            <dl>
                              <dt>{{$t('content.event.EventReservation.txt200')}}</dt>
                              <dd>
                                <img :src="item.map"  />
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <!-- // 이용 안내 -->
                      </div>
                    </template>
                  </BaseSlider>
                  <!-- // Swiper -->
                </div>
              </div>
              <div v-else>
                <div class="image-area" data-aos="fade-right">
                  <HallSlider
                    width='1400'
                    :autoplay='true'
                    :pagination="true"
                    :navigation="false"
                    :list="item.img">
                    <template v-slot="{ item }">
                      <img :src="item"  />
                    </template>
                  </HallSlider>
                </div>
                <div class='info-area'>
                  <div class='info-row' data-aos="fade-up">
                    <dl>
                      <dt>{{$t('content.event.EventReservation.txt197')}}</dt>
                      <dd>
                        <div class="info-price">
                          <strong>{{$t('content.event.EventReservation.txt198')}}</strong> {{item.price}}
                        </div>
                        <div>
                          <div class="info-person">
                            <strong>{{$t('content.event.EventReservation.txt184')}}</strong> {{item.person}}{{$t('content.event.EventReservation.txt184_2')}}
                          </div>
                          <ul class="list-dot" v-if="item.floor==='B1'&&item.title==='MAIN HALL'" v-html="$t('content.event.EventReservation.txt154')"></ul>
                        </div>
                      </dd>
                    </dl>
                  </div>
                  <div class='info-row' data-aos="fade-up">
                    <dl>
                      <dt>{{$t('content.event.EventReservation.txt185')}}</dt>
                      <dd>
                        <div class="info-time">
                          <div>
                            <strong>{{$t('content.event.EventReservation.txt186')}}</strong> 09:00 ~ 21:00
                          </div>
                          <div v-if="item.floor==='B1'&&item.title==='MAIN HALL'">
                            <strong>{{$t('content.event.EventReservation.txt187')}}</strong> 09:30 ~ 18:00
                          </div>
                        </div>
                        <div>
                          <ul class="list-dot" v-html="item.timeDesc">
                          </ul>
                        </div>
                      </dd>
                    </dl>
                  </div>
                  <div class='info-row' data-aos="fade-up">
                    <dl>
                      <dt v-html="$t('content.event.EventReservation.txt199')"></dt>
                      <dd v-html="item.facility"></dd>
                    </dl>
                  </div>
                  <div class='info-row' data-aos="fade-up">
                    <dl>
                      <dt>{{$t('content.event.EventReservation.txt200')}}</dt>
                      <dd>
                        <img :src="item.map"  />
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </BaseSlider>
      </div>
    <!-- // multi Room Section -->
    </div>
    <section class="guide-section" data-aos="fade-up">
      <div class="inner-md">
        <div class="title-wrap">
          <h6>{{$t('content.event.EventReservation.txt161')}}</h6>
        </div>
        <div class="guide-content">
          <!-- 대관 절차 -->
          <div class="process-wrap" >
            <div class="process-item">
                <div class="process-subtitle">
                  <span>1</span>
                  <!-- 사용 가능 일정 확인 -->
                  {{$t('content.event.EventReservation.txt162')}}
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt163')}}</dt>
                    <dd v-html="$t('content.event.EventReservation.txt164')">
                    </dd>
                  </dl>
                </div>
            </div>
            <div class="process-item">
                <div class="process-subtitle">
                  <span>2</span>
                  <div v-html="$t('content.event.EventReservation.txt165')">
                  </div>
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt166')}}</dt>
                    <dd>{{$t('content.event.EventReservation.txt167')}}</dd>
                  </dl>
                </div>
            </div>
            <div class="process-item">
                <div class="process-subtitle">
                  <span>3</span>
                  {{$t('content.event.EventReservation.txt168')}}
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt169')}}</dt>
                    <dd>{{$t('content.event.EventReservation.txt170')}}</dd>
                  </dl>
                </div>
            </div>
            <div class="process-item">
                <div class="process-subtitle">
                  <span>4</span>
                  {{$t('content.event.EventReservation.txt171')}}
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt172')}}</dt>
                    <dd v-html="$t('content.event.EventReservation.txt173_1')"></dd>
                  </dl>
                </div>
            </div>
            <div class="process-item">
                <div class="process-subtitle">
                  <span>5</span>
                  {{$t('content.event.EventReservation.txt174')}}
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt175')}}</dt>
                    <dd>{{$t('content.event.EventReservation.txt176')}}</dd>
                  </dl>
                </div>
            </div>
            <div class="process-item">
                <div class="process-subtitle">
                  <span>6</span>
                  {{$t('content.event.EventReservation.txt177')}}
                </div>
                <div class="process-desc">
                  <dl>
                    <dt>{{$t('content.event.EventReservation.txt178')}}</dt>
                    <dd  v-if="roomTabValue==0 || roomTabValue==1" v-html="$t('content.event.EventReservation.txt179')"></dd>
                    <dd  v-else v-html="$t('content.event.EventReservation.txt179_1')"></dd>
                  </dl>
                </div>
            </div>
          </div>
          <!-- // 대관 절차 -->
          <!-- 주의 사항 -->
            <div class="cautions-section" data-aos="fade-up">
              <div class="title-wrap">
                <h6 class="h6-20 icon-notice">{{$t('content.event.EventReservation.txt180')}}</h6>
                <p class="desc-text">
                  {{ $t('content.event.EventReservation.txt181_1') }}
                  <a href="#" @click.prevent="onFacilitiesPopup" v-html="$t('content.event.EventReservation.txt181_2')">
                  </a>{{ $t('content.event.EventReservation.txt181_3') }}
                </p>
              </div>
              <div class="caution-item-wrap">
                <dl class="cautions-item">
                  <dt>{{$t('content.event.EventReservation.txt34')}}</dt>
                  <dd>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt182')}}</li>
                    </ul>
                  </dd>
                </dl>
                <dl class="cautions-item">
                  <dt>{{$t('content.event.EventReservation.txt38')}}</dt>
                  <dd>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt39')}}</li>
                    </ul>
                  </dd>
                </dl>
                <dl class="cautions-item">
                  <dt>{{$t('content.event.EventReservation.txt40')}}</dt>
                  <dd v-if="roomTabValue==0 || roomTabValue==1" v-html="$t('content.event.EventReservation.txt41')"></dd>
                  <dd v-else v-html="$t('content.event.EventReservation.txt41_1')"></dd>
                </dl>
                <dl class="cautions-item">
                  <dt>{{$t('content.event.EventReservation.txt42')}}</dt>
                  <dd v-if="roomTabValue==0 || roomTabValue==1">
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt43')}}</li>
                      <li>{{$t('content.event.EventReservation.txt44_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt45_1')}}</li>
                      <li v-html="$t('content.event.EventReservation.txt45_2')"></li>
                      <li>{{$t('content.event.EventReservation.txt47')}}</li>
                      <li>{{$t('content.event.EventReservation.txt47_2')}}</li>
                      <li>{{$t('content.event.EventReservation.txt47_3')}}</li>
                      <li>{{$t('content.event.EventReservation.txt48')}}</li>
                      <li>{{$t('content.event.EventReservation.txt49')}}</li>
                    </ul>
                    <div>
                      <span>※ {{$t('content.event.EventReservation.txt50')}}</span>
                      <ul>
                        <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                        <li>{{$t('content.event.EventReservation.txt52')}}</li>
                        <li>{{$t('content.event.EventReservation.txt51_3')}}</li>
                      </ul>
                    </div>
                  </dd>
                  <dd v-else>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt43')}}</li>
                      <li>{{$t('content.event.EventReservation.txt44_2')}}</li>
                      <li v-html="$t('content.event.EventReservation.txt46')"></li>
                      <li v-html="$t('content.event.EventReservation.txt44_3')"></li>
                      <li>{{$t('content.event.EventReservation.txt51_2')}}</li>
                    </ul>
                    <div>
                      <span>※ {{$t('content.event.EventReservation.txt50')}}</span>
                      <ul>
                        <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                        <li>{{$t('content.event.EventReservation.txt52')}}</li>
                      </ul>
                    </div>
                  </dd>
                </dl>
                <!-- // 주의 사항 -->
                <!-- 대관 신청 문의 -->
                <dl class="cautions-item contact-section">
                  <dt>{{$t('content.event.EventReservation.txt54')}}</dt>
                  <dd>
                    <span class="manager">{{$t('content.event.EventReservation.txt56_1')}}</span><br/>
                    <ExternalLink
                      :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`"
                    ><span> {{$t('content.event.EventReservation.txt56_2')}}</span></ExternalLink><br/>
                    <span>{{$t('content.event.EventReservation.txt56_3')}}</span>
                  </dd>
                </dl>
                <!-- // 대관 신청 문의 -->
              </div>
            </div>
        </div>
      </div>
    </section>
    <!-- 버튼 -->
    <div class="btn-wrap">
      <button class="btn-basic" @click.prevent="goNext">
        {{$t('content.event.EventReservation.txt59')}}
      </button>
    </div>
    <!-- // 버튼 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseSlider from '@/components/slider/BaseSlider.vue';
import HallSlider from '@/components/slider/HallSlider.vue';
import BaseTab from '@/components/base/BaseTab.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';
import Facilities from '@/views/legal/Facilities.vue';

export default {
  name: 'Reservation',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseSlider,
    BaseTab,
    HallSlider,
    ExternalLink,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo,
    };
  },
  data() {
    return {
      tabValue: 0,
      roomTabValue: 0,
      eventTabValue: 0,
      vipRoomTabValue: 0,
      roomSlider: undefined,
      roomSliderIndex: undefined,
      multiRoomSlider: undefined,
      multiRoomSliderIndex: undefined,
      eventSlider: undefined,
      eventSliderIndex: undefined,
      vipRoomSlider: undefined,
      vipRoomSliderIndex: undefined,
      mainHallList: [this.requireAssetsImage('event/MainHall_01.jpg'),
        this.requireAssetsImage('event/MainHall_02.jpg'),
        this.requireAssetsImage('event/MainHall_03.jpg'),
        this.requireAssetsImage('event/MainHall_04.jpg'),
      ],
      roomList: [
        {
          floor: 'B1',
          title: 'MAIN HALL',
          price: this.$t('content.event.EventReservation.txt188'),
          person: '100',
          img: [this.requireAssetsImage('event/MainHall_01.jpg'),
            this.requireAssetsImage('event/MainHall_02.jpg'),
            this.requireAssetsImage('event/MainHall_03.jpg'),
            this.requireAssetsImage('event/MainHall_04.jpg'),
          ],
          map: this.requireAssetsImage('event/b1f_mainhall.png'),
          info: this.$t('content.event.EventReservation.txt154'),
          timeDesc: this.$t('content.event.EventReservation.txt155'),
          facility: this.$t('content.event.EventReservation.txt156_1'),
        },
        {
          floor: 'B1',
          title: 'MULTI ROOM B1F',
        },
        {
          floor: '5',
          title: 'EVENT HALL',
        },
        {
          floor: '5',
          title: 'MULTI ROOM 5F',
          price: this.$t('content.event.EventReservation.txt189'),
          person: '6',
          img: [this.requireAssetsImage('event/5F_MULTIROOM_1.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_2.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_3.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_4.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_multi.png'),
          info: this.$t('content.event.EventReservation.txt154'),
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_7'),
        },
        {
          floor: '5',
          title: 'EXECUTIVE ROOM',
        },
        {
          floor: '5',
          title: 'MEETING ROOM',
          price: this.$t('content.event.EventReservation.txt190_1'),
          person: '10',
          img: [this.requireAssetsImage('event/5F_MEETINGROOM_1.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_2.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_3.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_4.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_meeting.png'),
          info: this.$t('content.event.EventReservation.txt154'),
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_5'),
        },
        {
          floor: '5',
          title: 'DRAWING LOUNGE',
          price: this.$t('content.event.EventReservation.txt191'),
          person: '40',
          img: [this.requireAssetsImage('event/5F_DRAWINGLOUNGE_1.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_2.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_3.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_4.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_lounge.png'),
          info: this.$t('content.event.EventReservation.txt154'),
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_6'),
        },
      ],
      multiRoomList: [
        {
          img: this.requireAssetsImage('event/Multi_A_01.jpg'),
          title: 'Multi Room A',
          map: this.requireAssetsImage('event/b1f_multi.png'),
          price: this.$t('content.event.EventReservation.txt192'),
          person: '16',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt158_7'),
        },
        {
          img: this.requireAssetsImage('event/Multi_B_01.jpg'),
          title: 'Multi Room B',
          map: this.requireAssetsImage('event/b1f_multi_b.png'),
          price: this.$t('content.event.EventReservation.txt192'),
          person: '16',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt158_7'),
        },
        {
          img: this.requireAssetsImage('event/Multi_C_01.jpg'),
          title: 'Multi Room C',
          map: this.requireAssetsImage('event/b1f_multi_c.png'),
          price: this.$t('content.event.EventReservation.txt192'),
          person: '12',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt158_7'),
        },
        {
          img: this.requireAssetsImage('event/Multi_BC_01.jpg'),
          title: 'Multi Room B+C',
          content: this.$t('content.event.EventReservation.txt63'),
          map: this.requireAssetsImage('event/b1f_multi_bc.png'),
          price: this.$t('content.event.EventReservation.txt193'),
          person: '28',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt158_7'),
        },
        {
          img: this.requireAssetsImage('event/Multi_D_01.jpg'),
          title: 'Multi Room D',
          content: this.$t('content.event.EventReservation.txt64'),
          map: this.requireAssetsImage('event/b1f_multi_d.png'),
          price: this.$t('content.event.EventReservation.txt193'),
          person: '40',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt159'),
        },
        {
          img: this.requireAssetsImage('event/Multi_E_01.jpg'),
          title: 'Multi Room E',
          content: this.$t('content.event.EventReservation.txt65'),
          map: this.requireAssetsImage('event/b1f_multi_e.png'),
          price: this.$t('content.event.EventReservation.txt194'),
          person: '25',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt160'),
        },
        {
          img: this.requireAssetsImage('event/Multi_DE_01.jpg'),
          title: 'Multi Room D+E',
          content: this.$t('content.event.EventReservation.txt66'),
          map: this.requireAssetsImage('event/b1f_multi_de.png'),
          price: this.$t('content.event.EventReservation.txt195'),
          person: '60',
          timeDesc: this.$t('content.event.EventReservation.txt157'),
          facility: this.$t('content.event.EventReservation.txt159'),
        },
      ],
      eventHallList: [
        {
          img: [this.requireAssetsImage('event/5F_EVENTHALL_1_1.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_1_2.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_1_3.jpg'),
          ],
          title: 'Event Hall #1',
          map: this.requireAssetsImage('event/5f_event.png'),
          price: this.$t('content.event.EventReservation.txt190'),
          person: '40',
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_2'),
        },
        {
          img: [this.requireAssetsImage('event/5F_EVENTHALL_2_1.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_2_2.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_2_3.jpg'),
          ],
          title: 'Event Hall #2',
          map: this.requireAssetsImage('event/5f_event_2.png'),
          price: this.$t('content.event.EventReservation.txt190'),
          person: '40',
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_2'),
        },
      ],
      vipRoomList: [
        {
          img: [this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_1.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_2.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_3.jpg'),
              ],
          title: 'EXECUTIVE ROOM #1',
          price: this.$t('content.event.EventReservation.txt190_1'),
          person: '8',
          map: this.requireAssetsImage('event/5f_executive.png'),
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_3'),
        },
        {
          img: [this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_1.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_2.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_3.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_4.jpg'),
              ],
          title: 'EXECUTIVE ROOM #2',
          price: this.$t('content.event.EventReservation.txt190'),
          person: '10',
          map: this.requireAssetsImage('event/5f_executive_2.png'),
          timeDesc: this.$t('content.event.EventReservation.txt155_3'),
          facility: this.$t('content.event.EventReservation.txt156_4'),
        },
      ],
    };
  },
  mounted() {
    this.roomSlider = this.$refs.roomSlider;
    this.multiRoomSlider = this.$refs.multiRoomSlider;
    this.eventSlider = this.$refs.eventSlider;
    this.vipRoomSlider = this.$refs.vipRoomSlider;
  },
  computed: {
    multiRoom() {
      return this.multiRoomList.reduce((all, item) => all.concat(item.title), []);
    },
    room() {
      return this.roomList.reduce((all, item) => all.concat(item.title), []);
    },
    eventHall() {
      return this.eventHallList.reduce((all, item) => all.concat(item.title), []);
    },
    vipRoom() {
      return this.vipRoomList.reduce((all, item) => all.concat(item.title), []);
    },
  },
  methods: {
    onChangeMultiRoom(v) {
      this.tabValue = v;
    },
    onChangeRoom(v) {
      this.roomTabValue = v;
    },
    onChangeEventHall(v) {
      this.eventTabValue = v;
    },
    onChangeVipRoom(v) {
      this.vipRoomTabValue = v;
    },
    tabCallback() {
      const tmp = this.tabValue + 1;
      this.multiRoomSlider.$el.swiper.slideTo(tmp);
    },
    roomTabCallback() {
      const tmp = this.roomTabValue + 1;
      this.roomSlider.$el.swiper.slideTo(tmp);
    },
    eventTabCallback() {
      const tmp = this.eventTabValue + 1;
      this.eventSlider.$el.swiper.slideTo(tmp);
    },
    vipTabCallback() {
      const tmp = this.vipRoomTabValue + 1;
      this.vipRoomSlider.$el.swiper.slideTo(tmp);
    },
    onFacilitiesPopup() {
      this.showPopup(Facilities, { width: '800px' });
    },
    goNext() {
      const userInfo = this.$store.getters.getUserInfo();
      if (userInfo) {
        this.$router.push({ path: '/reservation/application' });
      } else {
        this.goLoginPage();
      }
    },
    goLoginPage() {
      const buttons = [{
        title: this.$t('content.event.Event.txt11'),
        type: 'line',
        class: '',
        handler: () => {
          this.$modal.hide('dialog');
        },
      }, {
        title: this.$t('content.event.Event.txt12'),
        default: true,
        class: '',
        handler: () => {
          this.$router.push({
            path: '/reservation/application',
          });
        },
      }];
      this.showConfirm(this.$t('confirmMessage.requiredLogin'), {
        buttons: buttons
      });
    }
  },
};
</script>

<style scoped>
.event-reservation-wrap .info-area {
    width: initial;
}
</style>
