<template>
  <div class="popup-wrap facilities">
    <div class="popup large">
      <div class="popup-header">
        <div class="terms-title">
          <h3>{{$t('content.event.Legal.Facilities.txt01')}}</h3>
        </div>
      </div>
      <div class="popup-contents">
        <div class="terms-container">
          <dl class="terms">
            <dt>{{$t('content.event.Legal.Facilities.txt02')}}</dt>
            <dd>
              {{$t('content.event.Legal.Facilities.txt03')}}

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt04')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt05')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt06')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt07')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt08')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt09')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt10')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt11')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt12')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt13')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt14')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt15')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt16')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt17')">

            </dd>
            <dt>{{$t('content.event.Legal.Facilities.txt18')}}</dt>
            <dd v-html="$t('content.event.Legal.Facilities.txt19')">

            </dd>
          </dl>
        </div>
      </div>
      <div class="btn-close" @click="doClose">close</div>
    </div>
  </div>
</template>

<script>
/**
 * 시설물 사용 약관
 */
export default {
  name: 'FacilitiesLegal',
  methods: {
    doClose() {
      this.$emit('close');
    },
  },

};
</script>

<style>
</style>
