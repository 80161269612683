/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Event: {
    txt01: `스타트업 생태계 구성원 누구나 참여할 수 있는 다양한 행사를 개최하여, 폭 넓은 네트워킹과
      <br />비즈니스 역량 강화의 기회를 제공합니다.`,
    // txt01: '월',
    // txt02: '일',
    // txt03: '에 진행하는 이벤트가 없습니다.',
    // txt04: '데모데이, 해커톤, 컨퍼런스 등',
    // txt05: '교육, 특강, 멘토링 등',
    // txt06: '네트워킹, 커뮤니티 강화 이벤트',
    // txt07: '설명회 등 기타 이벤트',
    // txt08: '한화회원 :한화그룹 임직원',
    // txt09: '입주사회원 :드림플러스(강남, 63핀테크)입주사',
    // txt10: '파트너회원 :드림플러스 파트너',
    // txt11: '일반회원 :이노베이션 허브 온라인 가입',
    // txt12: '캘린더형',
    // txt13: '목록형',
    txt10: '이벤트 신청',
    txt11: '취소',
    txt12: '로그인',
    txt13: '비회원 참여',
    txt14: '이벤트 신청 마감',
    txt15: '신청 페이지 연결',
    txt16: '이벤트 신청 완료',
    txt17: '자세히 보기',
    txt18: '마감',
    txt19: '신청 접수 전',
    txt20: '신청대상아님',
    txt21: '비회원으로 신청가능합니다.',
    txt22: '비회원 이벤트 신청 하기',
    txt23: '비회원 이벤트 신청',
    txt24: '신청내역보기',
    txt25: '취소됨',

    txt30: '이벤트 신청',
    txt31: '이벤트 신청내역',
    txt32: '비회원 이벤트 신청',

    txt33: '기타항목 입력',
    txt34: '휴대폰 번호',
    txt35: '-없이 숫자만 입력',
    txt36: '입력된 휴대폰 번호를 회원정보에 저장하겠습니다.',
    txt37: '메모',
    txt38: '남기고 싶은 메세지를 입력해 주세요.',
    txt39: '수집하는 개인 정보 항목 : 이벤트 신청자의 휴대폰 번호 및 이메일',
    txt40: '개인 정보의 수집/이용 목적 : 이벤트 참여 안내 및 신청자 확인',
    txt41: '개인 정보의 보유 및 이용 기간',
    txt42: `- 회원정보 추가 등록에 체크한 경우 가입 해지(탈퇴) 시까지
    <br />- 회원정보 추가 등록에 체크하지 않은 경우 이벤트 행사일 7일 후까지
    <br />`,
    txt43: '이벤트 신청을 위한 개인정보 수집에 동의합니다.',
    txt44: '신청',
    txt45: '이벤트 신청이 완료 되었습니다.',
    txt46: '이벤트 신청 취소',
    txt47: '확인',
    txt48: '이벤트 신청을 할 수 없습니다.',
    txt49: '이름',
    txt50: '이름 입력',
    txt51: '이메일',
    txt52: '이메일 입력',
    txt53: '휴대폰 번호',
    txt54: '-없이 숫자만 입력',
    txt55: '메모',
    txt56: '남기고 싶은 메세지를 입력해 주세요.',
    txt57: '수집하는 개인 정보 항목 : 이벤트 신청자의 휴대폰 번호 및 이메일',
    txt58: '개인 정보의 수집/이용 목적 : 이벤트 참여 안내 및 신청자 확인',
    txt59: '개인 정보의 보유 및 이용 기간 : 이벤트 행사일 7일후까지',
    txt60: '이벤트 신청을 위한 개인정보 수집에 동의합니다.',
    txt61: '신청',
    txt62: '이벤트 신청이 완료 되었습니다.',
    txt63: '확인',
    txt64: '이벤트 신청을 할 수 없습니다.',
    txt65: '이벤트 신청 내역이 없습니다.',
    alertMsg: {
      msg1: '기존에 신청완료된 데이터가 있습니다.<br/>중복신청은 불가능합니다.',
      msg2: '이벤트 모집정원초과 하였습니다.',
      msg3: `일시적인 기술적 오류로 이벤트신청이 완료되지 못했습니다.<br>
      다시 한 번 신청을 진행해주세요.`,
      msg4: '입력한 모든 정보가 삭제됩니다.<br/>이벤트 신청을 취소하시겠습니까?',
      msg5: '이벤트 신청을 취소 하시겠습니까?',
    },
  },
  EventReservation: {
    txt01: '드림플러스 강남센터 메인홀, 멀티룸 대관 신청',
    txt02: '메인홀 이미지',
    txt03: '<strong>605,000원</strong> / Hour <span class="vat">(VAT 포함)</span>',
    txt04: '수용 인원(100PY)',
    txt05: '테이블 (의자 포함) 세팅 시 140석',
    txt06: '의자 단독 세팅 시 180석',
    txt07: '대관 가능 시간',
    txt08: '월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)<br>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)',
    txt09: '법정휴일 및 주말 대관 불가',
    txt10: '입주사 토요일 대관 가능 (09:30 AM – 05:30 PM)',
    txt11: `최소 2시간 이상 대관 가능하며 시작/정리시간 각 30분 무료 제공
    <br />(무료 제공 시간 외 추가 시간이 필요한 경우 대관 신청 시 포함하여 신청)`,
    txt12: '사용가능 기자재',
    txt13: '무선 와이파이',
    txt14: `대형 LED 스크린 (1920 X 1080)
    <br />노트북 컨텐츠 송출 및 자동 풀 사이즈 리사이징`,
    txt15: '무선마이크 기본 2개 제공',
    txt16: `필요시 별도 지참 필요 : 노트북(맥북, 4K 해상도 연결 불가),
    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등`,
    txt17: '대관방법',
    txt18: '사용가능 일정 확인',
    txt19: `대관 필요 일자 조회 후 예약 가능시간의
    <br />시작/종료 시간을 선택하시기 바랍니다.
    <br/>(최소 2시간 이상 선택)
    <br />대관신청은 최소 1주 전, 2개월 이내의
    <br />일자만 예약 가능합니다.`,
    txt20: '대관신청서 작성 / 약관동의',
    txt21: `온라인 양식에 따라 대관신청서 작성 및
    <br />이용약관에 동의해 주시기 바랍니다.
    <br />스타트업 및 생태계 유관행사가 아닌 경우,
    <br />드림플러스 입주자 참여가 제한되는 경우
    <br />대관 이용이 제한될 수 있습니다.`,
    txt22: '대관신청서 승인',
    txt23: '작성하신 대관신청서 내역 확인 후 승인 여부에 대한 회신을 작성하신 메일로 안내 드립니다.',
    txt24: '입금 안내',
    txt25: `대관 승인이 된 경우 메일로 입금<br/>
    (예약금 –총 금액의 20% - 입금) 안내를 드리며, 입금확인 후 예약금은 환불이 불가합니다.<br/>
    대관일 5영업일 전까지 잔금 미 입금 시 예약을 취소하는 것으로 간주되며 <br/>
    입금 후 예약 취소 및 총 입금금액 환불이 불가합니다.
    `,
    txt25_1: `
    대관일 5영업일 전까지 미입금 시 예약을 취소하는 것으로 간주되며 입금 후 예약취소 및 총 입금금액 환불이 불가합니다.
입금 후 세금계산서가 발급됩니다.  
    `,
    txt26: '대관실 사용',
    txt27: `행사 당일, 행사장 사용을 위한 사전 교육
    <br />및 안내를 받으신 후 사용 가능합니다.`,
    txt28: '시설 검수 후 퇴장',
    txt29: `시설 훼손 및 물품 분실 시 손해배상 책임이
    <br />발생하며, 이로 인해 청구된 금액에 대해서는
    <br />청구일 기준 10영업일 이내에 해당 금액을
    <br />납부해 주셔야 합니다.`,
    txt29_1: '이벤트홀 #1,2 를 제외한 가구 위치 이동은 불가합니다.',
    txt30: '꼭 확인 부탁드립니다.',
    txt31: '아래 내용은 주요 사항에 대한 요약으로 상세 내용은 대관 신청 시 확인 가능한',
    txt32: '[시설물 사용 약관]',
    txt33: '을 참고하시기 바랍니다.',
    txt34: '주차',
    txt35: '- 메인홀 대관 행사 주최 차량에 한해 1 대 무료 주차지원 ( 행사당일 입차 후 요청 필요 )',
    txt36: '- 행사 참가자 유료 주차 가능(6,000원/1HOUR),',
    txt37: '단, 당일 주차 상황에 따라 불가한 경우도 있으니 대중 교통 이용 권장',
    txt38: '노트북',
    // txt39: 'HDMI 연결 가능한 노트북 준비(불가시 젠더 별도 준비) *메인홀의 경우 맥북, 4K 해상도 연결 불가',
    txt39: 'HDMI 연결 가능한 노트북 준비 (불가시 젠더 별도 준비)',
    txt40: '케이터링',
    txt41: `케이터링은 외부 업체 선정 하여 반입 가능합니다.<br/>
    케이터링 반입시 음식물 쓰레기 수거 가능한 케이터링 업체 선정 필수`,
    txt41_1: `케이터링은 외부 업체 선정 하여 반입 가능합니다.<br/>
    케이터링 반입시 음식물 쓰레기 수거 가능한 케이터링 업체 선정  필수<br/>
    (카펫 오염에 유의 하여, 규격이 큰 음식물 쓰레기통 준비) <br/>
    간단한 다과류(냄새가 발생하지 않는 개별 포장 된 구움과자, 떡, 핑거푸드 등) 및 음료 정도로 권장합니다.`,
    txt42: '기타사항',
    txt43: '- 행사 전/후 발생한 모든 쓰레기는 분리수거 하여 지정된 장소에 폐기',
    txt44: '- 행사 중 화물 E/V 통로를 이용하는 입주멤버의 이벤트홀 통행이 있을 수 있음',
    txt44_1: '- 시스템 설치, 브랜딩, 레이아웃 설치 시 사전에 신청화면 파일 첨부',
    txt44_2: '- 대관 홀 가구의 경우는 위치 이동 불가하나, EVENT HALL #1 #2 공간의 의자 위치 이동시 원복조건으로 이동 가능',
    txt44_3: `- 촬영 대관의 경우 장비 반입 및 방문객 인원 등 별도 협의 필요<br/> 
    &nbsp;&nbsp;기자재 이동시 보양 후 이동`,
    // txt45: '- 시스템 설치, 브랜딩 작업물 제작 전 설치 위치, 방법 등에 대한 사전 공유가 필요',
    txt45: '- 시스템 설치, 브랜딩, 레이아웃 설치 시 사전에 신청화면 파일 첨부',
    txt45_1: '- 시스템설치 브랜딩 좌석 레이아웃등은 신청내역 페이지의 첨부파일로 전달',
    txt45_2: '- 기타 좌석배치 관련 사항은 운영매니저와 협의하여 진행',
    txt46: '- 시설 훼손 및 물품 분실 시 손해배상 책임이 발생하며, 이로 인해 청구된 금액에 대해서는 청구일 기준 10영업일 이내에 납부 필요',
    txt47: '- 멀티룸 D,E 를 이용하시는 분들께서는 메인홀 대관이 있을경우 1 층 화장실 이용',
    txt47_2: '- 멀티룸 A,B,C 는 단독대관이 불가할 수 있습니다 ( 메인홀과 동시 대관 )',
    txt47_3: '- 외부 기자재는 보양작업 후 이동가능 ( 화물엘리베이터앞 , 비상출입구 적재 금지 )',
    txt48: '- 제작물 ( 현수막 , 포스터 ) 부착시 자석만 이용 ( 자석 지참 必 )',
    txt49: '- 사전 예약된 대관 룸 외 이용금지',
    txt50: '행사 준비시 참고 사항',
    txt51: '- 지하주차장 출입구 높이(2m) 제한에 따른 1t 차량 외 진입 불가',
    txt51_1: '- 지하주차장 출입구 높이 : 2m 제한에 따른 1t톤 차량 외 진입 불가(탑차 진입불가)',
    txt51_2: '- 현수막 및 포스터물 부착 불가 (POP, X 배너 준비)',
    txt51_3: '- 현수막 부착 불가',
    txt52: '- 화물 승강기 규격: 2140*1622*2240(가로*세로*높이)mm',
    txt53: '- 메인스크린 양 측 현수막 사이즈: 1200*3200(가로*세로)mm 미만으로 제작하여 타공 및 부착 가능',
    txt53_2: '- 메인홀 스크린 3 분할 시, 중앙 2240*1260 픽셀 / 사이드 640*1260 픽셀',
    txt54: '대관 신청 문의',
    txt55: '[일반 대관]',
    txt56: '배정임 매니저',
	  txt56_1: '진정애 매니저',
    txt56_2: 'space@dreamplus.asia',
    txt56_3: '02-520-1506',
    txt57: '[입주사 대관]',
    txt58: '심호준 매니저',
    txt59: '대관신청',
    txt60: `<div class='info-area' data-aos='fade-left' >
    <div class='info-use-header'>
      <span class='fee'>
        <strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>16인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>디지털 모니터(50인치)</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt61: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>20인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>디지털 모니터(50인치)</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt62: `<div class='info-area' data-aos='fade-left' data-aos-delay='500'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>16인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>디지털 모니터(50인치)</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt63: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>220,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>36인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>디지털 모니터(50인치)</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt64: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>220,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>40인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>빔프로젝터 & 프로젝터 스크린</li>
          <li>무선마이크 2개</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt65: `<div class='info-area' data-aos='fade-left' data-aos-delay='500'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>165,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>25인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>
            비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
            <br />단독 대관 불가
          </li>
          <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>빔프로젝터 & 프로젝터 스크린</li>
          <li>무선마이크 1개</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt66: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>385,000원</strong> / Hour <span class='vat'>(VAT 포함)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>수용인원</h4>
        <ul class='bullet-square-list'>
          <li>50인</li>
        </ul>
      </li>
      <li>
        <h4>대관 가능 시간</h4>
        <em>월-금 09:00 AM – 09:00 PM (최소 2시간 이상 예약 가능)</em><br>
        <em>토-일 09:30 AM – 06:00 PM (최소 2시간 이상 예약 가능)</em>
        <ul class='bullet-square-list'>
          <li>Multi Room D+E 연결하여 동시 대관 시</li>
          <li class='no-bullet'>비입주사 평일 및 주말 단독 대관 가능</li>
        </ul>
      </li>
      <li>
        <h4>사용가능 기자재</h4>
        <ul class='bullet-square-list'>
          <li>무선 와이파이</li>
          <li>빔프로젝터 & 프로젝터 스크린</li>
          <li>무선마이크 2개</li>
          <li>
            필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
            <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt67: '대관신청',
    txt68: '드림플러스 강남센터 이벤트홀 대관은 사용일 기준 최소 1주 전에 신청해 주세요.',
    txt69: '대관신청 확정',
    txt70: '대관신청 승인대기',
    txt71: '대관신청 불가',
    txt72: '신청내역보기',
    txt73: '대관신청',
    // 탭메뉴
    txt74: '대관 신청',
    txt75: '대관 신청 내역',
    // 진행스탭
    txt76: '행사내용입력',
    txt77: '시설이용선택',
    txt78: '기타항목 입력',
    txt79: '행사내용입력',
    txt80: '<span class="required-mark">필수항목</span>는 필수입력 항목입니다.',
    txt81: '행사명',
    txt82: '행사명 입력',
    txt83: '최대 20자까지 입력 가능 합니다.',
    txt84: '행사 소개',
    txt85: '행사 소개 입력',
    txt86: '주최사/주관사',
    txt87: '주최사/주관사 입력',
    txt88: '행사 참여자 소개',
    txt89: '행사 참여자 소개 입력',
    txt90: '예상 참여자 수',
    txt91: '예상 참여자 수 입력',
    txt92: '시설이용선택',
    txt93: '마이크 사용',
    txt94: '사용 (2ea)',
    txt95: '사용 안함',
    txt96: '좌석 세팅',
    txt97: '테이블+의자(최대 144석)',
    txt98: '의자(최대 200석)',
    txt99: '필요 좌석수 및 기타 요청사항 입력(100자 이내)',
    txt100: '필요좌석 미입력시 예상 참여인원수 만큼 좌석이 준비 됩니다.',
    txt101: '케이터링 반입',
    txt102: '미 반입',
    txt103: '반입',
    txt104: '케이터링 반입시 음식물쓰레기는 자체수거 필요합니다(공간내폐기불가)',
    txt105: '시설물 이용약관에 동의합니다.',
    txt106: '기타항목 입력',
    txt107: '휴대폰 번호',
    txt108: '-없이 숫자만 입력',
    txt109: '기타문의',
    txt110: '문의입력',
    txt111: '최대 100자까지 입력 가능 합니다.',
    txt112: '파일첨부',
    txt112_1: '파일 선택',
    txt113: '찾아보기',
    txt114: '수집하는 개인 정보 항목 : 신청자의 휴대폰 번호',
    txt115: '개인 정보의 수집/이용 목적 : 드림플러스 강남센터 대관신청',
    txt116: '개인 정보의 보유 및 이용 기간',
    txt117: `- 회원정보 추가 등록에 체크한 경우 가입 해지(탈퇴) 시까지
    <br />- 회원정보 추가 등록에 체크하지 않은 경우 대관일 7일 후까지
    <br />`,
    txt118: '대관신청을 위한 개인정보 수집에 동의합니다.',
    txt119: '대관신청이 완료 되었습니다.',
    txt120: `승인여부는 신청일 기준 3~5(영업일) 내 등록하신 메일로 답변 드립니다.
    <br />신청내역은 대관신청 내역 페이지에서 확인하실 수 있습니다.`,
    txt121: '대관신청을 할 수 없습니다.',
    txt122: `선택한 시간 또는 장소가 이미 예약완료 되었거나
    <br />일시적인 기술적 오류로 대관신청이 완료되지 못했습니다.
    <br />다시 한 번 신청을 진행해주세요.`,
    txt123: '대관신청 페이지로',

    // 대관신청내역
    txt124: '대관 취소를 원하시는 경우 02-520-1513, 1504로 유선 연락 부탁 드립니다.',
    txt125: '전체',
    txt126: '건',
    txt127: '신청일',
    txt128: '더보기',
    txt129: '대관 신청 내역이 없습니다.',

    txt130: '랜포트',
    txt131: '포디움',
    txt132: '테이블',
    txt133: '의자 추가',
    txt134: '프로젝터 추가',
    txt135: '장비 사용',
    txt136: '빔프로젝터(Main Hall 제외), 테이블/의자(1인) Multi Room',
    txt137: '케이터링 미 반입',
    txt138: '마이크 사용',
    txt139: '마이크 사용 안함',
    txt140: '아래 순서와 유의사항을 참고한 후 예약 접수를 진행합니다.',
    txt141: '사용 가능 일정 확인',
    txt142: '온라인 양식에 따라 예약 진행',
    txt143: '승인 여부 결과는 메일을 확인',
    txt144: '예약에 따른 입금 진행',
    txt145: '사전 교육 및 안내 받기',
    txt146: '공간 및 물품 정리 후 퇴실',

    // 공간 추가
    txt147: 'MAIN HALL',
    txt148: 'MULTI HALL',
    txt149: 'EVENT HALL',
    txt150: 'MULTI ROOM',
    txt151: 'EXECUTIVE ROOM',
    txt152: 'MEETING ROOM',
    txt153: 'DRAWING LOUNGE',
    txt154: `
    <li>테이블 (의자 포함) 셋팅시 최대 144석</li>
    <li>의자단독 셋팅시 180석</li>`,
    txt155: `
    <li>최소 2시간 이상 대관 예약 가능</li>
    <li>시작전/종료후 정리시간 각 30분 무료 제공</li>`,
    txt155_2: `
    <li>최소 2시간 이상 대관가능</li>`,
    txt155_3: `
    <li>최소 2시간 이상 대관가능</li>
    <li>시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)</li>
    `,
    txt156: `
      <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>무선와이파이</p>
          </li>
          <li>
            <i class='i_eventhall project'></i>
            <p class='txt'>대형 LED<br/>스크린</p>
          </li>
          <li>
            <i class='i_eventhall speak'></i>
            <p class='txt'>스피커</p>
          </li>
          <li>
            <i class='i_eventhall mic'></i>
            <p class='txt'>무선마이크<br/>(2개)</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>대형 LED스크린 해상도 : (1920X1080) 노트북 컨텐츠 송출 및 자동 풀 사이즈 리사이징</li>
          <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
        </ul>
      </div>
    `,
    txt156_1: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>대형 LED<br/>스크린</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>스피커</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>무선마이크<br/>(4개)</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>대형LED 스크린 해상도: (3520x1260)와이드 스크린으로 3분할하여 양쪽 브랜딩 가능<br/>
        ※ 3분할시 : 중앙 2,240 Ⅹ 1,260 픽셀(16:9) / 양쪽 브랜딩 640 Ⅹ 1,260 픽셀
        </li>
        <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
      </ul>
    </div>
  `,
  txt156_2: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall beam'></i>
          <p class='txt'>빔프로젝터</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>스크린</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>무선마이크<br/>(2대)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_pinmic'></i>
          <p class='txt'>핀마이크<br/>(1대)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_podium'></i>
          <p class='txt'>포디움</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>스피커</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI선 5M</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>5200ANSI, WUXGA(해상도 : 1920*1200 픽셀)</li>
        <li>맥북, 4K해상도 연결불가<br/>(필요시 클릭커 (포인터), HDMI 케이블 외 호환젠더 별도 지참)</li>
      </ul>
    </div>
  `,
  txt156_4: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>가구 (10인)</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>EXECUTIVE ROOM #1와 함께 대관 가능(폴딩도어 가벽 구조)</li>
      </ul>
    </div>
  `,
  txt156_3: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall monitor'></i>
          <p class='txt'>85인치 TV (4K)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_remote'></i>
          <p class='txt'>리모콘</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>가구 (8인)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>EXECUTIVE ROOM #2와 함께 대관 가능(폴딩도어 가벽 구조)</li>
      </ul>
    </div>
  `,
  txt156_5: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_table'></i>
          <p class='txt'>테이블 + 의자</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>본 대관시설은 Drawing Lounge 와 확장하여 사용 가능합니다</li>
      </ul>
    </div>
  `,
  txt156_6: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall ico_5f_sofa'></i>
          <p class='txt'>쇼파 및 가구</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>스피커</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>무선마이크<br/>(2대)</p>
        </li>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>스크린</p>
        </li>
        <li>
          <i class='i_eventhall beam'></i>
          <p class='txt'>빔프로젝터</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>본 대관시설은 MEETING ROOM과 확장하여 사용 가능합니다.</li>
        <li>5200ANSI, WUXGA(해상도 : 1920*1200 픽셀)</li>
        <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
      </ul>
    </div>
  `,
  txt156_7: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall monitor'></i>
          <p class='txt'>85인치 TV (4K)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_remote'></i>
          <p class='txt'>리모콘</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_pocket'></i>
          <p class='txt'>포켓볼 당구대</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>가구 (6인~8인)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>대관시 포켓폴 이용시에는 사전에 매니저에게 문의 부탁드립니다.</li>
      </ul>
    </div>
  `,
    txt157: `
      <li>
        비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며, 단독 대관 불가
      </li>
      <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
      <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
    `,
    txt158: `
       <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">무선 와이파이</p>
          </li>
          <li>
            <i class="i_eventhall monitor"></i>
            <p class="txt">디지털 모니터<br/>(50인치)</p>
          </li>
          <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">스피커</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
        </ul>
      </div>
    `,
    txt158_7: `
      <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class="i_eventhall wifi"></i>
          <p class="txt">무선 와이파이</p>
        </li>
        <li>
          <i class="i_eventhall monitor"></i>
          <p class="txt">디지털 모니터<br/>(50인치)</p>
        </li>
        <li>
          <i class="i_eventhall speak"></i>
          <p class="txt">스피커</p>
        </li>
        <li>
          <i class="i_eventhall mic"></i>
          <p class="txt">무선마이크<br>(1개)</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
      </ul>
    </div>
  `,
    txt159: `
      <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">무선<br/>와이파이</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">빔 프로젝터</p>
          </li>
          <li>
            <i class="i_eventhall project"></i>
            <p class="txt">프로젝터<br/>스크린</p>
          </li>
        <li>
            <i class="i_eventhall mic"></i>
            <p class="txt">무선 마이크<br/>(2개)</p>
        </li>
        <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">스피커</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
        </ul>
      </div>
    `,
    txt160: `
     <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">무선<br/>와이파이</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">빔 프로젝터</p>
          </li>
          <li>
            <i class="i_eventhall project"></i>
            <p class="txt">프로젝터<br/>스크린</p>
          </li>
        <li>
            <i class="i_eventhall mic"></i>
            <p class="txt">무선 마이크<br/>(2개)</p>
        </li>
        <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">스피커</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>맥북, 4K해상도 연결불가(필요시 클릭커(포인터), HDMI 케이블 외 호환 젠더 별도 지참)</li>
        </ul>
      </div>
    `,
    txt161: '대관 진행절차',
    txt162: '사용 가능 일정 확인',
    txt163: '사용공간을 예약 신청',
    txt164: `대관 필요 일자 조회 후 예약 가능시간의 시작/종료 시간을 선택하시기 바랍니다. (최소 2시간 이상 선택)<br/>
    대관 신청은 최소 1주일 전, 2개월 이내의 일자만 가능합니다.`,
    txt165: `대관신청서<br/>
    작성 / 이용약관 동의`,
    txt166: '온라인 양식에 따라 예약 진행',
    txt167: '온라인 양식에 따라 대관신청서 작성 및 이용약관에 동의해 주시기 바랍니다.',
    txt168: '대관신청서 승인',
    txt169: '승인 여부 결과는 메일을 확인',
    txt170: '작성하신 대관신청서 내역 확인 후 승인 여부에 대한 회신을 작성하신 메일로 안내 드립니다.',
    txt171: '입금 안내',
    txt172: '예약에 따른 입금 진행',
    txt173: `
    대관 승인이 된 경우 메일로 입금(예약금 –총 금액의 20% - 입금) 안내를 드리며, 입금확인 후 예약금은 환불이 불가합니다.<br/>
    대관일 5영업일 전까지 잔금 미 입금 시 예약을 취소하는 것으로 간주되며 입금 후 예약 취소 및 총 입금금액 환불이 불가합니다.
    `,
    txt173_1: `
    대관일 5영업일 전까지 미입금 시 예약을 취소하는 것으로 간주되며 입금 후 예약취소 및 총 입금금액 환불이 불가합니다.<br/>
입금 후 세금계산서가 발급됩니다. 
    `,
    txt174: '대관실 사용',
    txt175: '사전 교육 및 안내 받기',
    txt176: '행사 당일, 행사장 사용을 위한 사전 교육 및 안내를 받으신 후 사용 가능합니다.',
    txt177: '시설 검수 후 퇴실',
    txt178: '공간 및 물품 정리 후 퇴실',
    txt179: `
    시설 훼손 및 물품 분실 시 손해배상 책임이 발생하며 이로 인해 청구된 금액에 대해서는 청구일 기준 영업일 10일 이내에<br/>
    해당 금액을 납부해 주셔야 합니다.
    `,
    txt179_1: `
    이벤트홀 #1,2 를 제외한 가구 위치 이동은 불가합니다. 
    `,
    txt180: '꼭 알아두세요!',
    txt181: `
    아래 내용은 주요 사항에 대한 요약으로 상세 내용은 대관 신청 시 확인 가능한
    <a href="#" @click.prevent="onFacilitiesPopup">
      <strong> [시설물 이용 약관]</strong>
    </a> 을 참고하시기 바랍니다.`,
    txt181_1: '아래 내용은 주요 사항에 대한 요약으로 상세 내용은 대관 신청 시 확인 가능한',
    txt181_2: '<strong> [시설물 이용 약관]</strong>',
    txt181_3: ' 을 참고하시기 바랍니다.',
    txt182: '메인홀 대관 행사 주최 차량에 한해 1 대 무료 주차지원 ( 행사당일 입차 후 요청 필요 )',
    txt183: '행사 참가자 유료 주차 가능(6,000원/Hour), 단, 당일 주차 상황에 따라 불가한 경우도 있으니 대중 교통 이용 권장',
    txt184: '수용인원',
    txt184_2: '인',
    txt185: '대관 가능 시간',
    txt186: '평일',
    txt187: '주말',
    txt188: '605,000원 / Hour (VAT 포함)',
    txt189: '220,000원 / Hour (VAT 포함)',
    txt190: '330,000원 / Hour (VAT 포함)',
    txt190_1: '154,000원 / Hour (VAT 포함)',
    txt191: '990,000원 / Hour (VAT 포함)',
    txt192: '110,000원 / Hour (VAT 포함)',
    txt193: '220,000원 / Hour (VAT 포함)',
    txt194: '165,000원 / Hour (VAT 포함)',
    txt195: '385,000원 / Hour (VAT 포함)',
    txt196: '154,000원 / Hour (VAT 포함)',
    txt197: '대관 정보',
    txt198: '비용',
    txt199: '시설/설비',
    txt200: '상세위치',

    alertMsg: {
      msg1: '원하는 시간을<br>길게 누른 후 드래그 해주세요.',
      msg2: '기존에 신청완료된 데이터가 있습니다.<br/>중복신청은 불가능합니다.',
      msg3: '추가 대관신청은 이전 페이지의 대관신청 버튼을<br/> 통해 진행 부탁드립니다.',
      msg4: '파일사이즈가 너무큽니다',
      msg5: '입력한 모든 정보가 삭제됩니다.<br/>대관 신청을 취소하시겠습니까?',
      msg6: '',
    },
  },
  MobileEventReservation: {
    txt1: `<ul class='info-use'> <!--  Main Hall -->
             <li><strong class='title'><strong>605,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원(100PY)</strong>
                <ul class='bullet-square-list'>
                  <li>테이블 (의자 포함) 셋팅 시 144석</li>
                  <li>의자단독 셋팅 시 180석</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>최소 2시간 이상 대관 가능하며 시작/정리시간 각 30분 무료 제공
                    <br />(무료 제공 시간 외 추가 시간이 필요한 경우 대관 신청 시 포함하여 신청)
                  </li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>
                    대형LED 스크린 해상도: (3520x1260) 와이드 스크린으로 3분할하여 양쪽 브랜딩 가능<br/>
                    ※ 3분할시 : 중앙 2,240 Ⅹ 1,260 픽셀(16:9) / 양쪽 브랜딩 640 Ⅹ 1,260 픽셀
                  </li>
                  <li>무선마이크 기본 4개 제공</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(맥북, 4K 해상도 연결 불가),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선와이파이</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>대형 LED 스크린</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>무선마이크 (4개)</p>
                </li>
              </ul>
            </div>`,
    txt2: `<ul class='info-use'> <!--  Multi Room A-->
             <li><strong class='title'><strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>16인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>디지털 모니터(50인치)</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>디지털 모니터 (50인치)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
            </div>`,
    txt3: `<ul class='info-use'> <!--  Multi Room B-->
             <li><strong class='title'><strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>16인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>디지털 모니터(50인치)</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>디지털 모니터 (50인치)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt4: `<ul class='info-use'> <!--  Multi Room C-->
             <li><strong class='title'><strong>110,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>12인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>디지털 모니터(50인치)</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>디지털 모니터 (50인치)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt5: `<ul class='info-use'> <!--  Multi Room B+C-->
             <li><strong class='title'><strong>220,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>28인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>디지털 모니터(50인치)</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>디지털 모니터 (50인치)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt6: `<ul class='info-use'> <!--  Multi Room D-->
             <li><strong class='title'><strong>220,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>40인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>빔 프로젝터 & 프로젝터 스크린</li>
                  <li>무선마이크 2개</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>빔 프로젝터</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>프로젝터 스크린</p>
                </li>
               <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>무선 마이크 (2개)</p>
               </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt7: `<ul class='info-use'> <!--  Multi Room E-->
             <li><strong class='title'><strong>165,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>25인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>비입주사의 경우 메인홀과 함께 대관시에만 이용 가능하며,
                    <br />단독 대관 불가</li>
                  <li>메인홀 대관 시간과 동일 시간으로 대관 가능</li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>빔 프로젝터 & 프로젝터 스크린</li>
                  <li>무선마이크 1개</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>빔 프로젝터</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>프로젝터 스크린</p>
                </li>
               <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>무선 마이크 (1개)</p>
               </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt8: `<ul class='info-use'> <!--  Multi Room D + E-->
             <li><strong class='title'><strong>385,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>60인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 </em><br>
                <em>주말 09:30 – 18:00 </em>
                <ul class='bullet-square-list'>
                  <li>Multi Room D+E 연결하여 동시 대관 시<br>
                  비입주사 평일 단독 대관 가능
                  </li>
                </ul>
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>무선 와이파이</li>
                  <li>빔 프로젝터 & 프로젝터 스크린</li>
                  <li>무선마이크 2개</li>
                  <li>
                    필요시 별도 지참 필요 : 노트북(사전 연결 테스트 필요),
                    <br />클릭커(포인터), HDMI 케이블 외의 호환 젠더 등
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>빔 프로젝터</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>프로젝터 스크린</p>
                </li>
               <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>무선 마이크 (2개)</p>
               </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
              </ul>
              </ul>
            </div>`,
    txt09: '예약은 사용일 기준 최소 1주 전에 신청 가능합니다',
    txt10: '이용안내',
    txt11: '이용방법',
    txt12: '시작시간',
    txt13: '종료시간',
    txt14: '확인',
    txt15: '약관',
    txt16: '예약공간',
    txt17: '예약일',
    txt18: `<ul class='info-use'> <!--  EVENT HALL #1-->
             <li><strong class='title'><strong>330,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
              <li>
                <strong class='title'>수용 인원</strong>
                <ul class='bullet-square-list'>
                  <li>40인</li>
                </ul>
              </li>
              <li>
                <strong class='title'>대관 가능 시간</strong>
                <em>평일 09:00 – 21:00 <br/>
                ※ 최소 2시간 이상 대관가능<br/>
                ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
              </li>
              <li>
                <strong class='title'>시설/설비</strong>
                <ul class='bullet-square-list'>
                  <li>5200ANSI, WUXGA(해상도 : 1920*1200 픽셀)</li>
                  <li>맥북, 4해상도 연결불가(필요시 클릭커 (포인터), HDMI 케이블 외 호완젠더 별도 지참)</li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
               <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>무선 와이파이</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>빔 프로젝터</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>프로젝터 스크린</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>무선 마이크 (2대)</p>
               </li>
               <li>
                  <i class='i_eventhall ico_5f_pinmic'></i>
                  <p class='txt'>핀 마이크 (1대)</p>
               </li>
               <li>
                  <i class='i_eventhall ico_5f_podium'></i>
                  <p class='txt'>포디움</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>스피커</p>
                </li>
                <li>
                  <i class='i_eventhall ico_5f_hdmi'></i>
                  <p class='txt'>HDMI선 5M</p>
                </li>
              </ul>
            </div>`,
        txt19: `<ul class='info-use'> <!--  EVENT HALL #2-->
        <li><strong class='title'><strong>330,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
        <li>
          <strong class='title'>수용 인원</strong>
          <ul class='bullet-square-list'>
            <li>40인</li>
          </ul>
        </li>
        <li>
          <strong class='title'>대관 가능 시간</strong>
          <em>평일 09:00 – 21:00 <br/>
          ※ 최소 2시간 이상 대관가능<br/>
          ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
        </li>
        <li>
          <strong class='title'>시설/설비</strong>
          <ul class='bullet-square-list'>
            <li>5200ANSI, WUXGA(해상도 : 1920*1200 픽셀)</li>
            <li>맥북, 4해상도 연결불가(필요시 클릭커 (포인터), HDMI 케이블 외 호완젠더 별도 지참)</li>
          </ul>
        </li>
      </ul>
      <!-- 위항목에 일치하는 아이콘 노출 -->
      <div class='icons_list'>
         <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>무선 와이파이</p>
          </li>
           <li>
            <i class='i_eventhall beam'></i>
            <p class='txt'>빔 프로젝터</p>
          </li>
          <li>
            <i class='i_eventhall project'></i>
            <p class='txt'>프로젝터 스크린</p>
          </li>
          <li>
            <i class='i_eventhall mic'></i>
            <p class='txt'>무선 마이크 (2대)</p>
         </li>
         <li>
            <i class='i_eventhall ico_5f_pinmic'></i>
            <p class='txt'>핀 마이크 (1대)</p>
         </li>
         <li>
            <i class='i_eventhall ico_5f_podium'></i>
            <p class='txt'>포디움</p>
          </li>
         <li>
            <i class='i_eventhall speak'></i>
            <p class='txt'>스피커</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_hdmi'></i>
            <p class='txt'>HDMI선 5M</p>
          </li>
        </ul>
        </div>`,
        txt20: `<ul class='info-use'> <!-- multi ROOM 5f-->
          <li><strong class='title'><strong>220,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
          <li>
            <strong class='title'>수용 인원</strong>
            <ul class='bullet-square-list'>
              <li>6인</li>
            </ul>
          </li>
          <li>
            <strong class='title'>대관 가능 시간</strong>
            <em>평일 09:00 – 21:00 <br/>
            ※ 최소 2시간 이상 대관가능<br/>
            ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
            </em>
          </li>
          <li>
            <strong class='title'>시설/설비</strong>
            <ul class='bullet-square-list'>
              <li>무선 와이파이</li>
              <li>85인치 TV (4K)</li>
              <li>리모콘</li>
              <li>포켓볼 당구대</li>
              <li>가구 (6인~8인)</li>
              <li>HDMI </li>
            </ul>
            ※ 대관시 포켓폴 이용시에는 사전에 매니저에게 문의 부탁드립니다.
          </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
          <ul class='icons_track'>
            <li>
              <i class='i_eventhall wifi'></i>
              <p class='txt'>무선와이파이</p>
            </li>
            <li>
              <i class='i_eventhall monitor'></i>
              <p class='txt'>85인치 TV (4K)</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_remote'></i>
              <p class='txt'>리모콘</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_pocket'></i>
              <p class='txt'>포켓볼 당구대</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_furniture'></i>
              <p class='txt'>가구 (6인~8인)</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_hdmi'></i>
              <p class='txt'>HDMI</p>
            </li>
          </ul>
        </div>`,
        txt21: `<ul class='info-use'> <!-- EXECUTIVE ROOM #1 -->
        <li><strong class='title'><strong>154,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
        <li>
          <strong class='title'>수용 인원</strong>
          <ul class='bullet-square-list'>
            <li>8인</li>
          </ul>
        </li>
        <li>
          <strong class='title'>대관 가능 시간</strong>
          <em>평일 09:00 – 21:00 <br/>
          ※ 최소 2시간 이상 대관가능<br/>
          ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
          </em>
        </li>
        <li>
          <strong class='title'>시설/설비</strong>
          <ul class='bullet-square-list'>
            <li>무선 와이파이</li>
            <li>85인치 TV (4K)</li>
            <li>리모콘</li>
            <li>가구 (8인)</li>
            <li>HDMI</li>
          </ul>
          ※ EXECUTIVE ROOM #2와 함께 대관 가능(폴딩도어 가벽 구조)
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
          <ul class='icons_track'>
            <li>
              <i class='i_eventhall wifi'></i>
              <p class='txt'>무선와이파이</p>
            </li>
            <li>
              <i class='i_eventhall monitor'></i>
              <p class='txt'>85인치 TV (4K)</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_remote'></i>
              <p class='txt'>리모콘</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_furniture'></i>
              <p class='txt'>가구 (8인)</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_hdmi'></i>
              <p class='txt'>HDMI</p>
            </li>
          </ul>
        </div>`,
        txt22: `<ul class='info-use'> <!-- EXECUTIVE ROOM #2 -->
          <li><strong class='title'><strong>330,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
          <li>
            <strong class='title'>수용 인원</strong>
            <ul class='bullet-square-list'>
              <li>10인</li>
            </ul>
          </li>
          <li>
            <strong class='title'>대관 가능 시간</strong>
            <em>평일 09:00 – 21:00 <br/>
            ※ 최소 2시간 이상 대관가능<br/>
            ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
            </em>
          </li>
          <li>
            <strong class='title'>시설/설비</strong>
            <ul class='bullet-square-list'>
              <li>무선 와이파이</li>
              <li>가구 (10인)</li>
            </ul>
            ※ EXECUTIVE ROOM #1와 함께 대관 가능(폴딩도어 가벽 구조)
          </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
          <ul class='icons_track'>
            <li>
              <i class='i_eventhall wifi'></i>
              <p class='txt'>무선 와이파이</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_furniture'></i>
              <p class='txt'>가구 (10인)</p>
            </li>
          </ul>
        </div>`,
        txt23: `<ul class='info-use'> <!-- Meeting ROOM -->
        <li><strong class='title'><strong>154,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
        <li>
          <strong class='title'>수용 인원</strong>
          <ul class='bullet-square-list'>
            <li>10인</li>
          </ul>
        </li>
        <li>
          <strong class='title'>대관 가능 시간</strong>
          <em>평일 09:00 – 21:00 <br/>
          ※ 최소 2시간 이상 대관가능<br/>
          ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
          </em>
        </li>
        <li>
          <strong class='title'>시설/설비</strong>
          <ul class='bullet-square-list'>
            <li>무선 와이파이</li>
            <li>테이블 + 의자</li>
          </ul>
          ※본 대관시설은 Drawing Lounge 와 확장하여 사용 가능합니다.
        </li>
      </ul>
      <!-- 위항목에 일치하는 아이콘 노출 -->
      <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>무선 와이파이</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_table'></i>
            <p class='txt'>테이블 + 의자</p>
          </li>
        </ul>
      </div>`,
      txt24: `<ul class='info-use'> <!-- LOUNGE -->
      <li><strong class='title'><strong>990,000원</strong> / Hour <span class='vat'>(VAT 포함)</span></strong></li>
      <li>
        <strong class='title'>수용 인원</strong>
        <ul class='bullet-square-list'>
          <li>40인</li>
        </ul>
      </li>
      <li>
        <strong class='title'>대관 가능 시간</strong>
        <em>평일 09:00 – 21:00 <br/>
        ※ 최소 2시간 이상 대관가능<br/>
        ※ 시작/종료 시간 포함하여 예약 (앞/뒤 30분 제공 안함)
        </em>
      </li>
      <li>
        <strong class='title'>시설/설비</strong>
        <ul class='bullet-square-list'>
          <li>쇼파 및 가구</li>
          <li>스피커</li>
          <li>HDMI</li>
          <li>무선마이크 (2대)</li>
          <li>무선와이파이</li>
          <li>스크린</li>
          <li>빔프로젝터</li>  
        </ul>
        ※ 본 대관시설은 MEETING ROOM과 확장하여 사용 가능합니다.<br/>
        ※ 5200ANSI, WUXGA(해상도 : 1920*1200 픽셀)<br/>
        ※ 맥북, 4해상도 연결불가 (필요시 클릭커 (포인터), HDMI 케이블 외 호완젠더 별도 지참)
      </li>
    </ul>
    <!-- 위항목에 일치하는 아이콘 노출 -->
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall ico_5f_sofa'></i>
          <p class='txt'>쇼파 및 가구</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>스피커</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>무선마이크<br/>(2대)</p>
        </li>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>무선와이파이</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>스크린</p>
        </li>
        <li>
          <i class='i_eventhall beam'></i>
          <p class='txt'>빔프로젝터</p>
        </li>
      </ul>
    </div>`,
  },
  Legal: {
    Facilities: {
      txt01: '시설물 사용 약관',
      txt02: '제1조(목적)',
      txt03: `본 약관은 드림플러스 강남센터 시설물을 사용하고자 하는 자(이하 “이용자”라 함)로부터
      사용료 징수 및 관리에 필요한
      사항과 이용자의 시설물 사용 시 준수사항을 규정함을 목적으로 합니다.`,
      txt04: '제2조(용어의 정의)',
      txt05: `본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
      <br />① “제공자”라 함은 한화생명보험주식회사를 의미합니다.
      <br />② “이용자”라 함은 제공자의 사용 승인을 받아 정해진 방법에 따라 일정 범위 내의
      시설물을 이용하는 자를 의미합니다.
      <br />③ “시설물”이라 함은 이용자가 제공자의 사용 승인을 받아 이용할 수 있는 드림센터
      내의 건축물과 그 부대시설로 그
      <br />세부적인 범위는 제3조에서 규정하는 바와 같습니다.
      <br />④ “사용료”란 시설물 사용에 대한 대가로 이용자가 제공자에게 지급하는 비용을 의미합니다.
      <br />`,
      txt06: '제3조(시설물의 범위)',
      txt07: `① 이용자의 시설물 사용(대관) 범위는 다음과 같습니다.
      <br />1. 이벤트홀(B1F), 무선 마이크, 음향, 디지털 스크린 등 기자재, 의자, 테이블 등 가구
      <br />2. 그 밖에 제공자가 사용을 허용하는 시설(멀티룸 등)
      <br />② 제공자는 시설물의 보수, 기타 제반 사정에 따라 사용범위의 일부를 제한할 수 있습니다.
      <br />`,
      txt08: '제4조(사용신청 및 승인)',
      txt09: `① 시설물을 사용하고자 하는 자는 먼저 사용예정일의 사용가능 여부를 제공자에게 확인한 후
      시설물 사용 신청서(이하 “사용 신청서”라 함)를 작성하여 제공자에게 제출하여야 합니다.
      다만, 제공자가 운영상 불필요하다고 판단하는 경우에는 사용 신청서 제출을 생략할 수 있습니다.
      <br />② 이용자는 제1항에 따른 사용 신청서를 사용예정일 최소 1주전까지 제출 하여야 합니다.
      <br />③ 제공자는 제1항의 사용 신청서를 검토한 후 시설관리에 지장이 없고,
      사용에 중복이 없을 경우 시설사용을 승인할 수 있습니다. 단, 제7조의 시설물 사용 제한 사유에
      해당하는 경우에는 사용 승인이 제한될 수 있습니다.
      <br />`,
      txt10: '제5조 (사용료의 납부)',
      txt11: `① 이용자는 제공자에게 현금(제공자가 지정한 계좌로의 이체방식)으로 예약이행보증금(이하 “예약금”이라 함)
      및 시설 사용료를 지급하여야 합니다.
      <br />② 이용자는 제4조에 따른 신청서 제출 및 승인 후 5일(영업일 기준)이내로 예약금으로써 시설
      사용료의 20%를 제공자에게 지급하여야 하며 미납 시 예약이 자동 취소됩니다.
      <br />③ 제2항에 따른 예약금 납부 후 사용료 잔액은 신청한 사용일 전 5일
      이내로(예:7월 6일 대관시 7월 1일까지) 지급해야 합니다.(영업일 기준)
      <br />④ 5조 3항에 따른 납입기일 내에 사용료를 납입하지 못할 경우 대관 취소로 간주되어
      예약이 취소 될 수 있으며, 예약금은 반환되지 않습니다.
      (사용일이 5일 이하로 남은 경우 별도 안내된 일정에 입금 필요)
      <br />⑤ 제 3항에도 불구하고 사전 협의를 통해 행사 후 사용료를 지급하기로 한 경우 협의된 납입
      기일 내에 사용료를 정히 납부해야 하며, 납입하지 못한 경우 이용자는 체납된 금액에 대한 연이율19%를
      적용한 연체료를 일할 계산하여 산정한 금액을 가산하여 납부해야 합니다. 단, 납입 기일이 금융기관
      휴무일인 경우 그 다음 영업일까지 납입기일이 연장된 것으로 간주합니다.
      <br />`,
      txt12: '제6조 (사용 내용 변경, 신청 취소 및 환불 등)',
      txt13: `① 이용자가 사용 내용(시간, 이용 시설물 등) 변경 또는 신청을 취소하는 경우 서면으로 변경
      요청서 또는 취소신청서를 제출해야 합니다.
      <br />② 이용자가 시설 이용 신청을 취소하는 경우 제5조 제2항에 따라 기 납입한 예약금은 위약금으로
      간주되어 환급되지 않습니다.
      <br />③ 사용 내용 변경, 취소 요청은 5조 3항에 따른 사용료 납입기일 전까지 가능하며 납일기일 이후에는
      사용 내용 변경, 취소에 따른 사용료 환불이 불가합니다.
      <br />④ 제7조 제3항의 사유(*천재지변)로 이용자가 사용일(행사일)에 시설물을 이용할 수 없는 경우에는
      제공자는 이용자에게 책임을 지지 아니하며, 제5조 제2항 및 제3항에 따라 기납입한 예약금 및
      사용료 잔액을 반환합니다.
      <br />`,
      txt14: `제7조 (시설물 사용의 제한) 제공자는 다음 각호의 경우 이용자의 시설물 사용을 금지하거나
      제한할 수 있습니다.`,
      txt15: `① 이용자가 이 규정 또는 제공자의 시설물 운영관리상 필요한 지시사항을 위반한 경우
      <br />② 시설물 사용 목적이나 방법이 드림플러스 강남센터 운영목적에 부합하지 않거나 우선
      접수된 예약건이 있는 경우
      <br />③ 시설물 관리에 중대한 지장을 초래하는 경우 또는 천재지변 등 불가항력의 사유로 인하여
      시설물을 사용할 수 없게 된 경우
      <br />`,
      txt16: '제8조 (이용자 책임 및 손해배상)',
      txt17: `① 이용자는 사용기간 동안 시설물과 부대시설을 안전하고 청결하게 사용하도록 최대한 노력하여야 하며,
      사용 허가 받은 시설을 제공자의 동의 없이 타인에게 사용하도록 해서는 안됩니다.
      <br />② 이용자는 이벤트홀 내에서는 가급적 음료 외에 음식물을 반입하지 않도록 합니다.
      단, 제공자와 사전 협의 시 이벤트홀 및 회의실 내 케이터링 등 기타 음식물 반입이 가능하며,
      종료 후 음식물 쓰레기의 경우 이용자가 직접 수거하여 폐기해야 하며 기타 발생된 쓰레기에 대하서는
      이용자가 분리수거 하여 지정된 장소에 폐기해야 합니다.
      <br />③ 이용자가 시설물이나 부대설비를 고의∙과실로 인하여 멸실∙훼손 또는 분실하였을 때에는 동일
      물품으로 보상 및 원상복구 등의 손해배상 책임이 발생합니다. 이용자는 발생된 손해배상 책임에 대해서
      동일물품 보상 및 원상복구를 하지 않는 경우 제공자로부터 배상액을 통지 받은 날로부터 영업일 기준 10일
      이내 해당 금액을 납부해야 합니다.
      <br />④ 이용자는 인화물질 및 기타 화재사고가 발행할 수 있는 물건 등을 시설물 내로 반입을 해서는
      안되며, 이를 위반해서 발생하는 손해는 이용자가 부담해야 합니다.
      <br />⑤ 이용자는 시설물을 사용하는 이용자 소속 직원이나 행사 및 회의 참석자, 또는 이용자가 위탁
      받은 교육생을 관리할 책임이 있으며, 시설물을 실제 사용하는 자들의 주의의무 위반으로 인한 안전
      사고 발생시 책임은 이용자에게 있습니다.
      <br />⑥ 이용자는 사용기간 내에 특별한 장비나 구조물을 설치하고자 하는 경우에는 사용 신청서에
      기재하여 제공자의 사전 승인을 받아야 하며, 그 사용기간 종료와 동시에 설치물을 철거하고
      원상복구할 책임이 있습니다.
      <br />`,
      txt18: '제9조(기타)',
      txt19: `이 약관에서 규정되지 아니한 사항 또는 이 약관의 해석에 관하여 다툼이 있는 경우에는 제공자와
      이용자가 합의하여 결정하되, 합의가 이루어지지 아니한 경우에는 약관의 규제에 관한 법률,
      민법, 상법 등 관계 법령 및 공정 타당한 일반 관례에 따릅니다.
      <br />`,
    },
    Reservation: {
      txt01: '제1조(목적)',
      txt02: `본 약관은 드림플러스 강남센터 시설물을 사용하고자
      하는 자(이하 “이용자”라 함)로부터 사용료 징수 및 관리에 필요한
      사항과 이용자의 시설물 사용 시 준수사항을 규정함을 목적으로 합니다.`,
      txt03: '제2조(용어의 정의)',
      txt04: `본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
      <br />① “제공자”라 함은 한화생명보험주식회사를 의미합니다.
      <br />② “이용자”란 함은 제공자의 사용 승인을 받아 정해진 방법에 따라
      일정 범위 내의 시설물을 이용하는 자를 의미합니다.
      <br />본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
      <br />① “제공자”라 함은 한화생명보험주식회사를 의미합니다.
      <br />② “이용자”란 함은 제공자의 사용 승인을 받아 정해진 방법에 따라 일정
      범위 내의 시설물을 이용하는 자를 의미합니다.
      <br />`,
      txt05: '제3조(시설물의 범위)',
      txt06: `① 이용자의 시설물 사용(대관) 범위는 다음과 같습니다.
      <br />1. 이벤트홀(B1F), 무선 마이크, 음향, 디지털 스크린 등 기자재, 의자, 테이블 등 가구
      <br />2. 그 밖에 제공자가 사용을 허용하는 시설(멀티룸 등)
      <br />② 제공자는 시설물의 보수, 기타 제반 사정에 따라 사용범위의 일부를 제한할 수 있습니다.`,
      txt07: '제4조(사용신청 및 승인)',
      txt08: `① 시설물을 사용하고자 하는 자는 먼저 사용예정일의 사용가능 여부를 제공자에게 확인한 후 시설물 사용 신청서(이하 “사용
        신청서”라 함)를 작성하여 제공자에게 제출하여야 합니다. 다만, 제공자가 운영상 불필요하다고 판단하는 경우에는 사용 신청서 제출을
        생략할 수 있습니다.
        <br />② 이용자는 제1항에 따른 사용 신청서를 사용예정일 최소 1주전까지 제출 하여야 합니다.
        <br />③ 제공자는 제1항의 사용 신청서를 검토한 후 시설관리에 지장이 없고, 사용에 중복이 없을 경우 시설사용을 승인할 수
        있습니다. 단, 제7조의 시설물 사용 제한 사유에 해당하는 경우에는 사용 승인이 제한될 수 있습니다.`,
      txt09: '제5조 (사용료의 납부)',
      txt10: `① 이용자는 제공자에게 현금(제공자가 지정한 계좌로의 이체방식)으로 예약이행보증금(이하 “예약금”이라 함) 및 시설 사용료를
      지급하여야 합니다.
      <br />② 이용자는 제4조에 따른 신청서 제출 및 승인 후 5일(영업일 기준)이내로 예약금으로써 시설 사용료의 20%를
      제공자에게 지급하여야 하며 미납 시 예약이 자동 취소됩니다.
      <br />③ 제2항에 따른 예약금 납부 후 사용료 잔액은 신청한 사용일 전 5일 이내로(예:7월 6일 대관시 7월 1일까지)
      지급해야 합니다.(영업일 기준)
      <br />④ 5조 3항에 따른 납입기일 내에 사용료를 납입하지 못할 경우 대관 취소로 간주되어 예약이 취소 될 수 있으며,
      예약금은 반환되지 않습니다.
      <br />(사용일이 5일 이하로 남은 경우 별도 안내된 일정에 입금 필요)
      <br />⑤ 제 3항에도 불구하고 사전 협의를 통해 행사 후 사용료를 지급하기로 한 경우 협의된 납입 기일 내에 사용료를 정히
      납부해야 하며, 납입하지 못한 경우 이용자는 체납된 금액에 대한 연이율19%를 적용한 연체료를 일할 계산하여 산정한 금액을
      가산하여 납부해야 합니다. 단, 납입 기일이 금융기관 휴무일인 경우 그 다음 영업일까지 납입기일이 연장된 것으로 간주합니다.`,
      txt11: '제6조 (사용 내용 변경, 신청 취소 및 환불 등)',
      txt12: `① 이용자가 사용 내용(시간, 이용 시설물 등) 변경 또는 신청을 취소하는 경우 서면으로 변경 요청서 또는 취소신청서를 제출해야
      합니다.
      <br />② 이용자가 시설 이용 신청을 취소하는 경우 제5조 제2항에 따라 기 납입한 예약금은 위약금으로 간주되어 환급되지
      않습니다.`,
      txt13: '제7조 (시설물 사용의 제한) 제공자는 다음 각호의 경우 이용자의 시설물 사용을 금지하거나 제한할 수 있습니다.',
      txt14: `① 이용자가 이 규정 또는 제공자의 시설물 운영관리상 필요한 지시사항을 위반한 경우
      <br />② 시설물 사용 목적이나 방법이 드림플러스 강남센터 운영목적에 부합하지 않거나 우선 접수된 예약건이 있는 경우
      <br />③ 시설물 관리에 중대한 지장을 초래하는 경우 또는 천재지변 등 불가항력의 사유로 인하여 시설물을 사용할 수 없게 된
      경우`,
      txt15: '제8조 (이용자 책임 및 손해배상)',
      txt16: `① 이용자는 사용기간 동안 시설물과 부대시설을 안전하고 청결하게 사용하도록 최대한 노력하여야 하며, 사용 허가 받은 시설을
      제공자의 동의 없이 타인에게 사용하도록 해서는 안됩니다.
      <br />② 이용자는 이벤트홀 내에서는 가급적 음료 외에 음식물을 반입하지 않도록 합니다. 단, 제공자와 사전 협의 시 이벤트홀
      및 회의실 내 케이터링 등 기타 음식물 반입이 가능하며, 종료 후 음식물 쓰레기의 경우 이용자가 직접 수거하여 폐기해야 하며 기타
      발생된 쓰레기에 대하서는 이용자가 분리수거 하여 지정된 장소에 폐기해야 합니다.
      <br />③ 이용자가 시설물이나 부대설비를 고의∙과실로 인하여 멸실∙훼손 또는 분실하였을 때에는 동일 물품으로 보상 및 원상복구
      등의 손해배상 책임이 발생합니다. 이용자는 발생된 손해배상 책임에 대해서 동일물품 보상 및 원상복구를 하지 않는 경우 제공자로부터
      배상액을 통지 받은 날로부터 영업일 기준 10일 이내 해당 금액을 납부해야 합니다.
      <br />④ 이용자는 인화물질 및 기타 화재사고가 발행할 수 있는 물건 등을 시설물 내로 반입을 해서는 안되며, 이를 위반해서
      발생하는 손해는 이용자가 부담해야 합니다.
      <br />⑤ 이용자는 시설물을 사용하는 이용자 소속 직원이나 행사 및 회의 참석자, 또는 이용자가 위탁 받은 교육생을 관리할
      책임이 있으며, 시설물을 실제 사용하는 자들의 주의의무 위반으로 인한 안전 사고 발생시 책임은 이용자에게 있습니다.
      <br />⑥ 이용자는 사용기간 내에 특별한 장비나 구조물을 설치하고자 하는 경우에는 사용 신청서에 기재하여 제공자의 사전 승인을
      받아야 하며, 그 사용기간 종료와 동시에 설치물을 철거하고 원상복구할 책임이 있습니다.`,
      txt17: '제9조(기타)',
      txt18: `이 약관에서 규정되지 아니한 사항 또는 이 약관의 해석에 관하여 다툼이 있는 경우에는 제공자와 이용자가 합의하여 결정하되, 합의가
      이루어지지 아니한 경우에는 약관의 규제에 관한 법률, 민법, 상법 등 관계 법령 및 공정 타당한 일반 관례에 따릅니다.`,
    },
  },
};
