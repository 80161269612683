<template>
  <article class="event_reservation">
    <div class="box_title main">
      <h2 class="title">DREAMPLUS SPACE</h2>
      <p class="description" v-if="$i18n.locale == 'ko'">공간 대관</p>
      <p class="description" v-else>SPACE RENTAL</p>
    </div>
    <div class="center-tab-wrap">
      <a href="/" @click.prevent>Gangnam</a>
    </div>
    <div class="tab_list room-tab-list">
      <div class="tab_wrap" role="tablist" ref="roomTrack">
        <a href="javascript:void(0);"
            v-for="(tab, index) in room"
            :key="index"
            class="tab"
            ref="roomTabs"
            role="tab"
            :aria-selected="index === roomTabValue ? 'true' : ''"
            @click="RoomHander(index)"
        >{{tab}}</a>
      </div>
    </div>
    <section class="report_group" :class="roomTabValue===1||roomTabValue===2||roomTabValue===4 ? 'displaynone' : ''">
      <div class="tab_contents" role="tabpanel">
        <div class="report_box hall_swiper">
          <h3 class="room_title">{{roomList[roomTabValue].floor}}F | {{ roomList[roomTabValue].title }}</h3>
          <!-- 이미지 슬라이더 -->
          <MobileBaseSlider
            ref="swiperSlider"
            :list="roomList[roomTabValue].img"
            :autoplay='false'
            :navigation="false"
            :pagination="true"
          >
            <template v-slot="{ item }">
              <img :src="item" class="img_fit" :alt="`${roomList[roomTabValue].title} 이미지`" />
            </template>
          </MobileBaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
        <!-- 이미지 한장일 경우 -->
<!--        <div class="report_box hall_swiper">-->
<!--          <div class="swiper-slide">-->
<!--            <img :src="multiRoomList[0].img" class="img_fit" :alt="$t('content.event.EventReservation.txt02')" />-->
<!--          </div>-->
<!--        </div>-->
        <!-- //이미지 한장일 경우 -->
        <MobileBaseTab
          v-model="infoTab"
          :list="[$t('content.event.MobileEventReservation.txt10'), $t('content.event.MobileEventReservation.txt11')]"
          class="line expend">
        </MobileBaseTab>
        <div class="report_box tab_contents" role="tabpanel">
          <!-- 이용안내 -->
          <div class="info-area" v-show="infoTab === 0">
            <div v-html="roomList[roomTabValue].content"></div>
            <strong class="map-title">{{$t('content.event.EventReservation.txt200')}}</strong>
            <img :src="roomList[roomTabValue].map" class="img_fit" :alt="`${roomList[roomTabValue].title} 이미지`" />
          </div>
          <!-- // 이용 안내 -->

          <!-- 이용방법 -->
          <div class="rent-area" v-show="infoTab === 1">
            <div class="rent-info">
              <strong class="title">{{$t('content.event.EventReservation.txt17')}}</strong>
              <p class="comment">
                {{$t('content.event.EventReservation.txt140')}}
              </p>
              <ol class="guide-list">
                <li>
                  <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
                 <MobileToggle
                   :isShow="false">
                   <template slot="title">
                     <span class="icon" aria-hidden="true">1</span>
                     <strong class="title" name="title">{{$t('content.event.EventReservation.txt18')}}</strong>
                     <p class="des">{{$t('content.event.EventReservation.txt141')}}</p>
                   </template>
                   <template slot="content">
                     <div v-html="$t('content.event.EventReservation.txt19')"></div>
                   </template>
                 </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">2</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt20')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt142')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt21')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">3</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt22')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt143')}}</p>
                    </template>
                    <template slot="content">
                      {{$t('content.event.EventReservation.txt23')}}
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">4</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt24')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt144')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt25_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">5</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt26')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt145')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt27')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">6</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt28')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt146')}}</p>
                    </template>
                    <template slot="content">
                      <div v-if="roomTabValue===0" v-html="$t('content.event.EventReservation.txt29')"></div>
                      <div v-else v-html="$t('content.event.EventReservation.txt29_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
              </ol>
            </div>
            <!-- 주의 사항 -->
            <div class="tline caution-info">
              <div class="title-wrap">
                <strong class="title">
                  <i class="i_noti"></i>
                  <!--꼭 확인 부탁드립니다-->
                  {{$t('content.event.EventReservation.txt30')}}
                </strong>
                <p class="desc-text">
                  {{$t('content.event.EventReservation.txt31')}}
                  <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
                    <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
                  </a> {{$t('content.event.EventReservation.txt33')}}
                </p>
              </div>
              <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt34')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt35')}}</li>
                  </ul>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt38')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt39')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt40')}}
                </template>
                <template slot="content" v-if="roomTabValue===0">
                  <div v-html="$t('content.event.EventReservation.txt41')"></div>
                </template>
                <template slot="content" v-else>
                  <div v-html="$t('content.event.EventReservation.txt41_1')"></div>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt42')}}
                </template>
                <template slot="content" v-if="roomTabValue===0">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt45')}}</li>
                    <li>{{$t('content.event.EventReservation.txt45_1')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt45_2')"></li>
                    <li>{{$t('content.event.EventReservation.txt47')}}</li>
                    <li>{{$t('content.event.EventReservation.txt47_2')}}</li>
                    <li>{{$t('content.event.EventReservation.txt47_3')}}</li>
                    <li>{{$t('content.event.EventReservation.txt48')}}</li>
                    <li>{{$t('content.event.EventReservation.txt49')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                      <li>{{$t('content.event.EventReservation.txt51_3')}}</li>
                    </ul>
                  </div>
                </template>
                <template slot="content" v-else>
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt44_2')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt46')"></li>
                    <li v-html="$t('content.event.EventReservation.txt44_3')"></li>
                    <li>{{$t('content.event.EventReservation.txt51_2')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                    </ul>
                  </div>
                </template>
              </MobileToggle>
            </div>
            <!-- // 주의 사항 -->
            <!-- 대관 신청 문의 -->
            <div class="tline call-info">
              <strong class="title" >{{$t('content.event.EventReservation.txt54')}}</strong>
              <dl class="label_cont">
                <dt class="label">{{$t('content.event.EventReservation.txt56_1')}}</dt>
                <dd class="cont">
                  <ExternalLink :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`"><span> {{$t('content.event.EventReservation.txt56_2')}}</span>
                  </ExternalLink>
                  <p>{{$t('content.event.EventReservation.txt56_3')}}</p>
                </dd>
              </dl>
            </div>
            <!-- // 대관 신청 문의 -->
          </div>
          <!-- //이용방법 -->
        </div>
      </div>
    </section>
    <section class="report_group" :class="roomTabValue!==1?'displaynone':''">
<!--      <MobileBaseTab-->
<!--        v-model="tabValue"-->
<!--        :list="multiRoom"-->
<!--        @input="tabCliek">-->
<!--      </MobileBaseTab>-->
      <h3 class="room_title multi_room_title">B1 | MULTI ROOM</h3>
      <div class="tab_list">
        <div class="tab_wrap" role="tablist" ref="track">
          <a href="javascript:void(0);"
             v-for="(tab, index) in multiRoom"
             :key="index"
             class="tab"
             ref="tabs"
             role="tab"
             :aria-selected="index === tabValue ? 'true' : ''"
             @click="Hander(index)"
          >{{tab}}</a>
        </div>
      </div>
      <div class="tab_contents" role="tabpanel">
        <div class="report_box hall_swiper">
          <!-- 이미지 슬라이더 -->
          <MobileBaseSlider
            ref="swiperSlider2"
            :list="multiRoomList"
            :autoplay='false'
            :navigation="false"
            :pagination="true"
            @change="onChangeMultiRoom"
          >
            <template v-slot="{ item }">
              <img :src="item.img" class="img_fit" :alt="`${item.title} 이미지`" />
            </template>
          </MobileBaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
        <!-- 이미지 한장일 경우 -->
<!--        <div class="report_box hall_swiper">-->
<!--          <div class="swiper-slide">-->
<!--            <img :src="multiRoomList[0].img" class="img_fit" :alt="$t('content.event.EventReservation.txt02')" />-->
<!--          </div>-->
<!--        </div>-->
        <!-- //이미지 한장일 경우 -->
        <MobileBaseTab
          v-model="infoTab"
          :list="[$t('content.event.MobileEventReservation.txt10'), $t('content.event.MobileEventReservation.txt11')]"
          class="line expend">
        </MobileBaseTab>
        <div class="report_box tab_contents" role="tabpanel">
          <!-- 이용안내 -->
          <div class="info-area" v-show="infoTab === 0">
            <div v-html="multiRoomList[tabValue].content"></div>
            <strong class="map-title">상세위치</strong>
            <img :src="multiRoomList[tabValue].map" class="img_fit" :alt="`${multiRoomList[tabValue].title} 이미지`" />
          </div>
          <!-- // 이용 안내 -->

          <!-- 이용방법 -->
          <div class="rent-area" v-show="infoTab === 1">
            <div class="rent-info">
              <strong class="title">{{$t('content.event.EventReservation.txt17')}}</strong>
              <p class="comment">
                {{$t('content.event.EventReservation.txt140')}}
              </p>
              <ol class="guide-list">
                <li>
                  <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
                 <MobileToggle
                   :isShow="false">
                   <template slot="title">
                     <span class="icon" aria-hidden="true">1</span>
                     <strong class="title" name="title">{{$t('content.event.EventReservation.txt18')}}</strong>
                     <p class="des">{{$t('content.event.EventReservation.txt141')}}</p>
                   </template>
                   <template slot="content">
                     <div v-html="$t('content.event.EventReservation.txt19')"></div>
                   </template>
                 </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">2</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt20')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt142')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt21')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">3</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt22')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt143')}}</p>
                    </template>
                    <template slot="content">
                      {{$t('content.event.EventReservation.txt23')}}
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">4</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt24')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt144')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt25')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">5</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt26')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt145')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt27')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">6</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt28')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt146')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt29')"></div>
                    </template>
                  </MobileToggle>
                </li>
              </ol>
            </div>
            <!-- 주의 사항 -->
            <div class="tline caution-info">
              <div class="title-wrap">
                <strong class="title">
                  <i class="i_noti"></i>
                  <!--꼭 확인 부탁드립니다-->
                  {{$t('content.event.EventReservation.txt30')}}
                </strong>
                <p class="desc-text">
                  {{$t('content.event.EventReservation.txt31')}}
                  <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
                    <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
                  </a> {{$t('content.event.EventReservation.txt33')}}
                </p>
              </div>
              <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt34')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt35')}}</li>
                  </ul>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt38')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt39')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt40')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt41')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt42')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt45')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt46')"></li>
                    <li>{{$t('content.event.EventReservation.txt47')}}</li>
                    <li>{{$t('content.event.EventReservation.txt47_2')}}</li>
                    <li>{{$t('content.event.EventReservation.txt47_3')}}</li>
                    <li>{{$t('content.event.EventReservation.txt48')}}</li>
                    <li>{{$t('content.event.EventReservation.txt49')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                      <li>{{$t('content.event.EventReservation.txt51_3')}}</li>
                    </ul>
                  </div>
                </template>
              </MobileToggle>
            </div>
            <!-- // 주의 사항 -->
            <!-- 대관 신청 문의 -->
            <div class="tline call-info">
              <strong class="title">{{$t('content.event.EventReservation.txt54')}}</strong>
              <dl class="label_cont">
                <dt class="label">{{$t('content.event.EventReservation.txt56')}}</dt>
                <dd class="cont">
                  <ExternalLink :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`">
                    <span> {{$t('content.event.EventReservation.txt56_2')}}</span>
                  </ExternalLink>
                  <p>{{$t('content.event.EventReservation.txt56_3')}}</p>
                </dd>
              </dl>
            </div>
            <!-- // 대관 신청 문의 -->
          </div>
          <!-- //이용방법 -->
        </div>
      </div>
    </section>
    <!-- EVENT HALL -->
    <section class="report_group" :class="roomTabValue!==2?'displaynone':''">
      <h3 class="room_title multi_room_title">5F | EVENT HALL</h3>
      <div class="tab_list">
        <div class="tab_wrap" role="tablist" ref="eventHallTrack">
          <a href="javascript:void(0);"
             v-for="(tab, index) in eventHall"
             :key="index"
             class="tab"
             ref="eventTabs"
             role="tab"
             :aria-selected="index === eventTabValue ? 'true' : ''"
             @click="EventHallHander(index)"
          >{{tab}}</a>
        </div>
      </div>
      <div class="tab_contents" role="tabpanel">
        <div class="report_box hall_swiper">
           <!-- 이미지 슬라이더 -->
           <MobileBaseSlider
            ref="eventSlider"
            :list="eventHallList"
            :autoplay='false'
            :navigation="false"
            :pagination="false"
            @change="onChangeEventHall"
          >
            <template v-slot="{ item }">
              <!-- <img :src="item.img" class="img_fit" :alt="`${item.title} 이미지`" /> -->
              <MobileBaseSlider
                ref="swiperSlider"
                :list="item.img"
                :autoplay='false'
                :navigation="false"
                :pagination="true"
              >
                <template v-slot="{ item }">
                  <img :src="item" class="img_fit" :alt="`${item.title} 이미지`" />
                </template>
              </MobileBaseSlider>
            </template>
          </MobileBaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
        <MobileBaseTab
          v-model="infoTab"
          :list="[$t('content.event.MobileEventReservation.txt10'), $t('content.event.MobileEventReservation.txt11')]"
          class="line expend">
        </MobileBaseTab>
        <div class="report_box tab_contents" role="tabpanel">
          <!-- 이용안내 -->
          <div class="info-area" v-show="infoTab === 0">
            <div v-html="eventHallList[eventTabValue].content"></div>
            <strong class="map-title">상세위치</strong>
            <img :src="eventHallList[eventTabValue].map" class="img_fit" :alt="`${eventHallList[eventTabValue].title} 이미지`" />
          </div>
          <!-- // 이용 안내 -->

          <!-- 이용방법 -->
          <div class="rent-area" v-show="infoTab === 1">
            <div class="rent-info">
              <strong class="title">{{$t('content.event.EventReservation.txt17')}}</strong>
              <p class="comment">
                {{$t('content.event.EventReservation.txt140')}}
              </p>
              <ol class="guide-list">
                <li>
                  <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
                 <MobileToggle
                   :isShow="false">
                   <template slot="title">
                     <span class="icon" aria-hidden="true">1</span>
                     <strong class="title" name="title">{{$t('content.event.EventReservation.txt18')}}</strong>
                     <p class="des">{{$t('content.event.EventReservation.txt141')}}</p>
                   </template>
                   <template slot="content">
                     <div v-html="$t('content.event.EventReservation.txt19')"></div>
                   </template>
                 </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">2</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt20')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt142')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt21')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">3</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt22')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt143')}}</p>
                    </template>
                    <template slot="content">
                      {{$t('content.event.EventReservation.txt23')}}
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">4</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt24')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt144')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt25_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">5</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt26')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt145')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt27')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">6</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt28')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt146')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt29_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
              </ol>
            </div>
            <!-- 주의 사항 -->
            <div class="tline caution-info">
              <div class="title-wrap">
                <strong class="title">
                  <i class="i_noti"></i>
                  <!--꼭 확인 부탁드립니다-->
                  {{$t('content.event.EventReservation.txt30')}}
                </strong>
                <p class="desc-text">
                  {{$t('content.event.EventReservation.txt31')}}
                  <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
                    <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
                  </a> {{$t('content.event.EventReservation.txt33')}}
                </p>
              </div>
              <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt34')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt35')}}</li>
                  </ul>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt38')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt39')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt40')}}
                </template>
                <template slot="content">
                  <div v-html="$t('content.event.EventReservation.txt41_1')"></div>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt42')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt44_2')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt46')"></li>
                    <li v-html="$t('content.event.EventReservation.txt44_3')"></li>
                    <li>{{$t('content.event.EventReservation.txt51_2')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                    </ul>
                  </div>
                </template>
              </MobileToggle>
            </div>
            <!-- // 주의 사항 -->
            <!-- 대관 신청 문의 -->
            <div class="tline call-info">
              <strong class="title">{{$t('content.event.EventReservation.txt54')}}</strong>
              <dl class="label_cont">
                <dt class="label">{{$t('content.event.EventReservation.txt56_1')}}</dt>
                <dd class="cont">
                  <ExternalLink :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`"><span> {{$t('content.event.EventReservation.txt56_2')}}</span>
                  </ExternalLink>
                  <p>{{$t('content.event.EventReservation.txt56_3')}}</p>
                </dd>
              </dl>
            </div>
            <!-- // 대관 신청 문의 -->
          </div>
          <!-- //이용방법 -->
        </div>
      </div>
    </section>
     <!-- EXECUTIVE ROOM -->
     <section class="report_group" :class="roomTabValue!==4?'displaynone':''">
      <h3 class="room_title multi_room_title">5F | EXECUTIVE ROOM</h3>
      <div class="tab_list">
        <div class="tab_wrap" role="tablist" ref="vipRoomTrack">
          <a href="javascript:void(0);"
             v-for="(tab, index) in vipRoom"
             :key="index"
             class="tab"
             ref="vipTabs"
             role="tab"
             :aria-selected="index === vipTabValue ? 'true' : ''"
             @click="VipRoomHander(index)"
          >{{tab}}</a>
        </div>
      </div>
      <div class="tab_contents" role="tabpanel">
        <div class="report_box hall_swiper">
            <!-- 이미지 슬라이더 -->
            <MobileBaseSlider
            ref="vipSlider"
            :list="vipRoomList"
            :autoplay='false'
            :navigation="false"
            :pagination="false"
            @change="onChangeVipRoom"
          >
            <!-- <template v-slot="{ item }">
              <img :src="item.img" class="img_fit" :alt="`${item.title} 이미지`" />
            </template> -->
            <template v-slot="{ item }">
              <!-- <img :src="item.img" class="img_fit" :alt="`${item.title} 이미지`" /> -->
              <MobileBaseSlider
                ref="swiperSlider"
                :list="item.img"
                :autoplay='false'
                :navigation="false"
                :pagination="true"
              >
                <template v-slot="{ item }">
                  <img :src="item" class="img_fit" :alt="`${item.title} 이미지`" />
                </template>
              </MobileBaseSlider>
            </template>
          </MobileBaseSlider>
          <!-- // 이미지 슬라이더 -->
        </div>
        <MobileBaseTab
          v-model="infoTab"
          :list="[$t('content.event.MobileEventReservation.txt10'), $t('content.event.MobileEventReservation.txt11')]"
          class="line expend">
        </MobileBaseTab>
        <div class="report_box tab_contents" role="tabpanel">
          <!-- 이용안내 -->
          <div class="info-area" v-show="infoTab === 0">
            <div v-html="vipRoomList[vipTabValue].content"></div>
            <strong class="map-title">상세위치</strong>
            <img :src="vipRoomList[vipTabValue].map" class="img_fit" :alt="`${vipRoomList[vipTabValue].title} 이미지`" />
          </div>
          <!-- // 이용 안내 -->

          <!-- 이용방법 -->
          <div class="rent-area" v-show="infoTab === 1">
            <div class="rent-info">
              <strong class="title">{{$t('content.event.EventReservation.txt17')}}</strong>
              <p class="comment">
                {{$t('content.event.EventReservation.txt140')}}
              </p>
              <ol class="guide-list">
                <li>
                  <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
                 <MobileToggle
                   :isShow="false">
                   <template slot="title">
                     <span class="icon" aria-hidden="true">1</span>
                     <strong class="title" name="title">{{$t('content.event.EventReservation.txt18')}}</strong>
                     <p class="des">{{$t('content.event.EventReservation.txt141')}}</p>
                   </template>
                   <template slot="content">
                     <div v-html="$t('content.event.EventReservation.txt19')"></div>
                   </template>
                 </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">2</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt20')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt142')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt21')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">3</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt22')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt143')}}</p>
                    </template>
                    <template slot="content">
                      {{$t('content.event.EventReservation.txt23')}}
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">4</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt24')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt144')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt25_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">5</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt26')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt145')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt27')"></div>
                    </template>
                  </MobileToggle>
                </li>
                <li>
                  <MobileToggle
                    :isShow="false">
                    <template slot="title">
                      <span class="icon" aria-hidden="true">6</span>
                      <strong class="title">{{$t('content.event.EventReservation.txt28')}}</strong>
                      <p class="des">{{$t('content.event.EventReservation.txt146')}}</p>
                    </template>
                    <template slot="content">
                      <div v-html="$t('content.event.EventReservation.txt29_1')"></div>
                    </template>
                  </MobileToggle>
                </li>
              </ol>
            </div>
            <!-- 주의 사항 -->
            <div class="tline caution-info">
              <div class="title-wrap">
                <strong class="title">
                  <i class="i_noti"></i>
                  <!--꼭 확인 부탁드립니다-->
                  {{$t('content.event.EventReservation.txt30')}}
                </strong>
                <p class="desc-text">
                  {{$t('content.event.EventReservation.txt31')}}
                  <a href="#" class="underline-text" @click.prevent="onFacilitiesPopup">
                    <strong>{{$t('content.event.EventReservation.txt32')}}</strong>
                  </a> {{$t('content.event.EventReservation.txt33')}}
                </p>
              </div>
              <!-- accordion 활성화 active 클래스 추가 및 aria-expended="true" -->
              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt34')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt35')}}</li>
                  </ul>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt38')}}
                </template>
                <template slot="content">
                  {{$t('content.event.EventReservation.txt39')}}
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt40')}}
                </template>
                <template slot="content">
                  <div v-html="$t('content.event.EventReservation.txt41_1')"></div>
                </template>
              </MobileToggle>

              <MobileToggle
                :isShow="false">
                <template slot="title">
                  {{$t('content.event.EventReservation.txt42')}}
                </template>
                <template slot="content">
                  <ul>
                    <li>{{$t('content.event.EventReservation.txt43')}}</li>
                    <li>{{$t('content.event.EventReservation.txt44_2')}}</li>
                    <li v-html="$t('content.event.EventReservation.txt46')"></li>
                    <li v-html="$t('content.event.EventReservation.txt44_3')"></li>
                    <li>{{$t('content.event.EventReservation.txt51_2')}}</li>
                  </ul>
                  <div class="reference-mark">
                    <span>{{$t('content.event.EventReservation.txt50')}}</span>
                    <ul>
                      <li>{{$t('content.event.EventReservation.txt51_1')}}</li>
                      <li>{{$t('content.event.EventReservation.txt52')}}</li>
                    </ul>
                  </div>
                </template>
              </MobileToggle>
            </div>
            <!-- // 주의 사항 -->
            <!-- 대관 신청 문의 -->
            <div class="tline call-info">
              <strong class="title">{{$t('content.event.EventReservation.txt54')}}</strong>
              <dl class="label_cont">
                <dt class="label">{{$t('content.event.EventReservation.txt56_1')}}</dt>
                <dd class="cont">
                  <ExternalLink :to="`mailto:${$t('content.event.EventReservation.txt56_2')}`"><span> {{$t('content.event.EventReservation.txt56_2')}}</span>
                  </ExternalLink>
                  <p>{{$t('content.event.EventReservation.txt56_3')}}</p>
                </dd>
              </dl>
            </div>
            <!-- // 대관 신청 문의 -->
          </div>
          <!-- //이용방법 -->
        </div>
      </div>
    </section>
    <!-- 버튼 -->
    <div class="btn_bottom_wrap">
      <button class="btn-basic" @click.prevent="goNext">
        {{$t('content.event.EventReservation.txt59')}}
      </button>
    </div>
    <!-- // 버튼 -->
  </article>
</template>

<script>
import Intro from '@/views/reservation/Intro.vue';
import MobileBaseSlider from '@/components/slider/MobileBaseSlider.vue';
import MobileToggle from '@/components/base/MobileToggle.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';
import '@/assets/m_css/popup.css';

export default {
  name: 'MobileReservation',
  extends: Intro,
  components: {
    MobileBaseSlider,
    MobileToggle,
    MobileBaseTab,
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo,
    };
  },
  data() {
    return {
      offsetArray: [],
      totalWidth: 0,
      infoTab: 0,
      tabValue: 0,
      roomTabValue: 0,
      eventTabValue: 0,
      vipTabValue: 0,
      roomList: [
        {
          floor: 'B1',
          title: 'MAIN HALL',
          price: '605,000',
          person: '100',
          content: this.$t('content.event.MobileEventReservation.txt1'),
          img: [this.requireAssetsImage('event/MainHall_01.jpg'),
            this.requireAssetsImage('event/MainHall_02.jpg'),
            this.requireAssetsImage('event/MainHall_03.jpg'),
            this.requireAssetsImage('event/MainHall_04.jpg'),
          ],
          map: this.requireAssetsImage('event/b1f_mainhall.png'),
        },
        {
          floor: 'B1',
          title: 'MULTI ROOM B1',
        },
        {
          floor: '5',
          title: 'EVENT HALL',
        },
        {
          floor: '5',
          title: 'MULTI ROOM 5F',
          content: this.$t('content.event.MobileEventReservation.txt20'),
          price: '605,000',
          person: '100',
          img: [this.requireAssetsImage('event/5F_MULTIROOM_1.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_2.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_3.jpg'),
            this.requireAssetsImage('event/5F_MULTIROOM_4.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_multi.png'),
        },
        {
          floor: '5',
          title: 'EXECUTIVE ROOM',
        },
        {
          floor: '5',
          title: 'MEETING ROOM',
          content: this.$t('content.event.MobileEventReservation.txt23'),
          price: '154,000',
          person: '20',
          img: [this.requireAssetsImage('event/5F_MEETINGROOM_1.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_2.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_3.jpg'),
            this.requireAssetsImage('event/5F_MEETINGROOM_4.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_meeting.png'),
        },
        {
          floor: '5',
          title: 'DRAWING LOUNGE',
          content: this.$t('content.event.MobileEventReservation.txt24'),
          img: [this.requireAssetsImage('event/5F_DRAWINGLOUNGE_1.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_2.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_3.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_4.jpg'),
            this.requireAssetsImage('event/5F_DRAWINGLOUNGE_5.jpg'),
          ],
          map: this.requireAssetsImage('event/5f_lounge.png'),
        },
      ],
      multiRoomList: [
        // {
        //   img: this.requireAssetsImage('event/MainHall_01.jpg'),
        //   title: 'Main Hall',
        //   content: this.$t('content.event.MobileEventReservation.txt1'),
        // },
        {
          img: this.requireAssetsImage('event/Multi_A_01.jpg'),
          title: 'Multi Room A',
          content: this.$t('content.event.MobileEventReservation.txt2'),
          map: this.requireAssetsImage('event/b1f_multi.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_B_01.jpg'),
          title: 'Multi Room B',
          content: this.$t('content.event.MobileEventReservation.txt3'),
          map: this.requireAssetsImage('event/b1f_multi_b.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_C_01.jpg'),
          title: 'Multi Room C',
          content: this.$t('content.event.MobileEventReservation.txt4'),
          map: this.requireAssetsImage('event/b1f_multi_c.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_BC_01.jpg'),
          title: 'Multi Room B+C',
          content: this.$t('content.event.MobileEventReservation.txt5'),
          map: this.requireAssetsImage('event/b1f_multi_bc.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_D_01.jpg'),
          title: 'Multi Room D',
          content: this.$t('content.event.MobileEventReservation.txt6'),
          map: this.requireAssetsImage('event/b1f_multi_d.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_E_01.jpg'),
          title: 'Multi Room E',
          content: this.$t('content.event.MobileEventReservation.txt7'),
          map: this.requireAssetsImage('event/b1f_multi_e.png'),
        },
        {
          img: this.requireAssetsImage('event/Multi_DE_01.jpg'),
          title: 'Multi Room D+E',
          content: this.$t('content.event.MobileEventReservation.txt8'),
          map: this.requireAssetsImage('event/b1f_multi_de.png'),
        },
      ],
      eventHallList: [
        {
          img: [this.requireAssetsImage('event/5F_EVENTHALL_1_1.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_1_2.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_1_3.jpg'),
          ],
          // img: this.requireAssetsImage('event/5F_EVENTHALL_1_1.jpg'),
          title: 'Event Hall #1',
          map: this.requireAssetsImage('event/5f_event.png'),
          content: this.$t('content.event.MobileEventReservation.txt18'),
        },
        {
          img: [this.requireAssetsImage('event/5F_EVENTHALL_2_1.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_2_2.jpg'),
          this.requireAssetsImage('event/5F_EVENTHALL_2_3.jpg'),
          ],
          title: 'Event Hall #2',
          map: this.requireAssetsImage('event/5f_event_2.png'),
          content: this.$t('content.event.MobileEventReservation.txt19'),
        },
      ],
      vipRoomList: [
        {
          img: [this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_1.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_2.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_1_3.jpg'),
              ],
          title: 'EXECUTIVE ROOM #1',
          map: this.requireAssetsImage('event/5f_executive.png'),
          content: this.$t('content.event.MobileEventReservation.txt21'),
        },
        {
          img: [this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_1.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_2.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_3.jpg'),
                this.requireAssetsImage('event/5F_EXECUTIVEROOM_2_4.jpg'),
              ],
          title: 'EXECUTIVE ROOM #2',
          map: this.requireAssetsImage('event/5f_executive_2.png'),
          content: this.$t('content.event.MobileEventReservation.txt22'),
        },
      ],
    };
  },
  mounted() {
    this.$refs.tabs.forEach((e, i) => {
      this.offsetArray[i] = e.offsetLeft;
    });
    this.totalWidth = this.$refs.track.clientWidth;
    this.moveScroll(this.tabValue);
  },
  computed: {
    multiRoom() {
      return this.multiRoomList.reduce((all, item) => all.concat(item.title), []);
    },
    eventHall() {
      return this.eventHallList.reduce((all, item) => all.concat(item.title), []);
    },
    vipRoom() {
      return this.vipRoomList.reduce((all, item) => all.concat(item.title), []);
    },
  },
  methods: {
    moveScroll(idx) {
      const $thisLi = this.$refs.tabs[idx];
      const offsetLeft = this.offsetArray[idx];
      let posX = offsetLeft;
      if (idx > 0) {
        posX = offsetLeft - ((this.totalWidth / 2) - ($thisLi.clientWidth / 2));
      } else {
        posX = 0;
      }
      this.$refs.track.scrollLeft = posX;
    },
    Hander(event) {
      // const index = Array.from(event.currentTarget.children).indexOf(event.target);
      const index = event;
      for (let i = 0; i < this.$refs.track.children.length; i += 1) {
        if (index === i) {
          this.tabCliek(i);
        }
      }
      this.moveScroll(index);
      this.tabCliek(index);
    },
    RoomHander(event) {
      const index = event;
      for (let i = 0; i < this.$refs.roomTrack.children.length; i += 1) {
        if (index === i) {
          this.roomTabCliek(i);
        }
      }
      this.roomTabCliek(index);
      this.moveScroll(0);
      this.tabCliek(0);
      this.eventTabCliek(0);
      this.vipTabCliek(0);
    },
    EventHallHander(event) {
      const index = event;
      for (let i = 0; i < this.$refs.eventHallTrack.children.length; i += 1) {
        if (index === i) {
          this.eventTabCliek(i);
        }
      }
      this.eventTabCliek(index);
    },
    VipRoomHander(event) {
      const index = event;
      for (let i = 0; i < this.$refs.vipRoomTrack.children.length; i += 1) {
        if (index === i) {
          this.vipTabCliek(i);
        }
      }
      this.vipTabCliek(index);
    },
    onChangeMultiRoom(v) {
      this.tabValue = v;
      this.moveScroll(v);
    },
    onChangeEventHall(v) {
      this.eventTabValue = v;
    },
    onChangeVipRoom(v) {
      this.vipTabValue = v;
    },
    tabCliek(i) {
      this.tabValue = i;
      this.$refs.swiperSlider2.$el.swiper.slideTo(i + 1);
    },
    roomTabCliek(i) {
      this.roomTabValue = i;
    },
    eventTabCliek(i) {
      this.eventTabValue = i;
      this.$refs.eventSlider.$el.swiper.slideTo(i + 1);
    },
    vipTabCliek(i) {
      this.vipTabValue = i;
      this.$refs.vipSlider.$el.swiper.slideTo(i + 1);
    },
    goNext() {
      const userInfo = this.$store.getters.getUserInfo();
      if (userInfo) {
        this.$router.push({ path: '/reservation/application' });
      } else {
        this.goLoginPage()
      }
    },
    goLoginPage() {
      const buttons = [{
        title: this.$t('content.event.Event.txt11'),
        type: 'line',
        class: '',
        handler: () => {
          this.$modal.hide('dialog');
        },
      }, {
        title: this.$t('content.event.Event.txt12'),
        default: true,
        class: '',
        handler: () => {
          this.$router.push({
            path: '/reservation/application'
          });
        },
      }];
      this.showConfirm(this.$t('confirmMessage.requiredLogin'), {
        buttons: buttons
      });
    }
  },
};
</script>

<style scoped>
.event-reservation-wrap .info-area {
    width: initial;
}
</style>
