/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  Event: {
    txt01: `We provide a wide range of opportunities of networking and business
     by holding various events open to anyone in the startup ecosystem.`,
    // txt01: '월',
    // txt02: '일',
    // txt03: '에 진행하는 이벤트가 없습니다.',
    // txt04: '데모데이, 해커톤, 컨퍼런스 등',
    // txt05: '교육, 특강, 멘토링 등',
    // txt06: '네트워킹, 커뮤니티 강화 이벤트',
    // txt07: '설명회 등 기타 이벤트',
    // txt08: '한화회원 :한화그룹 임직원',
    // txt09: '입주사회원 :드림플러스(강남, 63핀테크)입주사',
    // txt10: '파트너회원 :드림플러스 파트너',
    // txt11: '일반회원 :이노베이션 허브 온라인 가입',
    // txt12: '캘린더형',
    // txt13: '목록형',
    txt10: 'Apply',
    txt11: 'Cancel',
    txt12: 'Login',
    txt13: 'Non-member Apply',
    txt14: 'Application Closed',
    txt15: 'Connecting to Application Page',
    txt16: 'Application Submitted',
    txt17: '자세히 보기',
    txt18: '마감',
    txt19: 'Unregistered Application',
    txt20: 'Not elligible for application',
    txt21: '비회원으로 신청가능합니다.',
    txt22: 'Non-member Apply',
    txt23: 'Non-member Apply',
    txt24: 'View Application Details',

    txt30: 'Apply',
    txt31: 'History of application',
    txt32: 'Non-member Apply',

    txt33: 'Enter ohters',
    txt34: 'Cell phone number',
    txt35: 'Enter number without -',
    txt36: 'Add this number to my membership information',
    txt37: 'Memo',
    txt38: 'Please enter the message',
    txt39: 'Personal information to be collected : mobile number and e-mail of event applicant',
    txt40: 'Purpose of collecting and using personal information : guide for event participation and confirmation of participants',
    txt41: 'Period of retaining and using personal information',
    txt42: `- For those who opt-in additional information registration, the data is retained until termination (withdrawal) of membership.
    <br />- For non opt-in members, data retained until 7days after the rental day
    <br />`,
    txt43: 'I agree to the collection of personal information to apply for the event.',
    txt44: 'Apply',
    txt45: 'You have successfully applied to participate in an event',
    txt46: 'Cancel',
    txt47: 'Confirm',
    txt48: 'You cannot apply for the event.',
    txt49: 'Name',
    txt50: 'Enter Name',
    txt51: 'Email',
    txt52: 'Enter email',
    txt53: 'Cell phone number',
    txt54: 'Enter number without -',
    txt55: 'Memo',
    txt56: 'Please enter the message.',
    txt57: 'Personal information to be collected : mobile number and e-mail of event applicant',
    txt58: 'Purpose of collecting and using personal information : guide for event participation and confirmation of participants',
    txt59: 'Retention and Period of use : Until 7 days after the event day',
    txt60: 'I agree to the collection of personal information to apply for the event.',
    txt61: 'Apply',
    txt62: 'You have successfully applied to participate in an event.',
    txt63: 'Confirm',
    txt64: 'You cannot apply for the event.',
    txt65: 'No Results.',
    alertMsg: {
      msg1: 'You have already applied.<br/>Duplicate application is not possible.',
      msg2: 'Application is full',
      msg3: `Application failed due to technical errors.<br>
      Please try again.`,
      msg4: 'All information entered will be deleted.<br/>Would you like to cancel your application?',
      msg5: 'Would you like to cancel your application?',
    },
  },
  EventReservation: {
    txt01: 'DREAMPLUS GANGNAM CENTER, Main Hall Rental Application',
    txt02: 'MAIN HALL',
    txt03: '<strong>₩605,000</strong> / Hour <span class="vat">(VAT included)</span>',
    txt04: 'Capacity(100PY)',
    txt05: '144 seats with tables',
    txt06: '180 seats without table',
    txt07: 'Rental Hours',
    txt08: 'Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)',
    txt09: 'Not available on official holidays',
    txt10: 'Tenants are able to rent on Sat (9:30 AM - 5:30 PM)',
    txt11: `Rentals are available for at least 2 hours, and 30 minutes before/after the event are provided free of charge.
     (If more time is needed than given free time, please include it in the rental application)`,
    txt12: 'Available Equipment',
    txt13: 'Wireless WIFI',
    txt14: 'Large LED Screen (1920 X 1080)',
    txt15: 'Wireless mic (2 provided)',
    txt16: `What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
     clicker (pointer), compatible gender changer except for HDMI cable`,
    txt17: 'How to Rent',
    txt18: 'Check the availability',
    txt19: `Please select start/finish time from reservation available time after searching the date you want to use.
    You should apply for the space rent at least 1 weeks in advance within 2 months. `,
    txt20: 'Rental application form and the terms and condition for use ',
    txt21: `Please fill out the rental application and agree to the terms and conditions for use according to the online form.
     If the event is not related to startup and ecosystem,
     and if participation of DREAMPLUS tenants is restricted,
     rental service may be restricted.`,
    txt22: 'Approval of application',
    txt23: `After reviewing the details of the rental application you have completed,
    you will be notified if it is approved by replying your e-mail of application.`,
    txt24: 'Payment',
    txt25: `If your application is approved, it will be notified by e-mail how to pay
     (Reservation fee- 20% of the total rental fee).
     After confirming the payment,
     the deposit is not refundable. If the balance is not paid 5 business days prior to the reserved date, it is considered as cancellation.
     Once paid, it is impossible to cancel the reservation or refund.`,
     txt25_1: `
     If payment is not made by 5 business days before the rental date, the reservation will be considered canceled. Once payment is made, cancellations and refunds of the total payment amount are not possible.
     A tax invoice will be issued after payment.
    `,
    txt26: 'Use of Event Hall/Multi Room',
    txt27: 'You are able to use the space after receiving prior education for using the venue on the same day of event.',
    txt28: 'Exit after facility inspection',
    txt29: `In the event of damage to the facility or loss of goods,
     the liability for damages shall arise,
     and the amount charged for such damages shall be paid within<br /> 10 business days of the billing date.`,
    txt29_1: 'Furniture cannot be moved within the rental space, except for chairs in EVENT HALL #1 and #2, which can be returned to their original position after moving.',
    txt30: 'Please make sure to check the content.',
    txt31: 'The following is a summary of the main matters, and please refer to,',
    txt32: ' the terms and conditions for use of facilities',
    txt33: ' for more details that can be available at the time of rental application.',
    txt34: 'Parking',
    txt35: '- One free parking is provided for event host who rent the Event Hall (Please ask for the parking permit on the event day)',
    txt36: '- Paid parking is available for participants (₩6,000/hour)',
    txt37: 'However, parking may not be available sometimes, public transportation is recommended.',
    txt38: 'Laptop',
    txt39: 'Laptop working with HDMI (if not, please prepare appropriate cable separately)',
    txt40: 'Catering',
    txt41: 'If food is brought in, the catering company shall collect and bring out food waste with them.',
    txt41_1: `Catering can be brought in from an external provider.<br/>
    If catering is brought in, it is required to select a provider that can dispose of food waste.<br/>
    Care should be taken to avoid carpet contamination, and large food waste bins should be prepared.<br/>
    Simple snacks (such as individually packaged cookies, rice cakes, finger foods, etc.) and drinks are recommended.`,
    txt42: 'Others',
    txt43: '- All garbage generated before and after the event shall be collected separately and disposed of in a designated place.',
    txt44: '- During the event, the building occupant may have access to the Event Hall through the cargo elevator passage.',
    txt44_1: '- System Installation, Branding, and Layout Setup: Files must be attached in the application screen when applying.',
    txt44_2: '- Furniture Relocation in Rental Halls: Furniture cannot be relocated; but, chairs in EVENT HALL #1 and #2 can be moved with the condition that they are restored to their original positions.',
    txt44_3: `- Photography Rental Policy: Special arrangements are required for equipment entry and visitor numbers.<br/> 
    &nbsp;&nbsp;External Equipment: Can be moved after protective work`,
    txt45: '- System Installation, Branding, and Layout Setup: Files must be attached in the application screen when applying.',
    txt45_1: '- System Installation, Branding, Seating Layout, etc.: These details should be provided as attachments on the application page.',
    txt45_2: '- Seating Arrangement: Other seating-related matters should be discussed and arranged with the operations manager.',
    txt46: '- In the event of facility damage or loss of goods, liability for damages shall occur, and the amount charged for such damages shall be paid within<br /> 10 business days of the billing date.',
    txt47: '- Multi-Room D and E: Guests using these rooms must use the 1st-floor restrooms if there is a main hall rental.',
    txt47_2: '- Multi-Room A, B, C: These rooms may not be available for exclusive rentals (can be rented simultaneously with the main hall).',
    txt47_3: '- External Equipment: Can be moved after protective work (Loading is prohibited in front of the freight elevator and emergency exit).',
    txt48: '- Installation of Signage (Banners, Posters): Only magnets can be used for attachment (Magnets must be brought by the renter).',
    txt49: '- Usage Restrictions: The rented room cannot be used for any purpose other than the pre-reserved event.',
    txt50: 'Event preparation tips',
    txt51: '- Vehicles other than 1 ton trucks are restricted to enter underground parking lot due to the height limit (2m)',
    txt51_1: '- Vehicles other than 1 ton trucks are restricted to enter underground parking lot due to the height limit (2m) (No access for large trucks.)',
    txt51_2: '- Banner and Poster Attachment: Banners and posters cannot be attached (POP, X-banner should be prepared).',
    txt51_3: '- Banners cannot be attached to the walls.',
    txt52: '- Cargo elevator size: 2140*1622*2240 (width*length*height)mm',
    txt53: `- Banner size on both sides of the main screen (vertical):
    Banners shall be prepared smaller than 1200*3200 (width*length)mm to perforate and attach`,
    txt53_2: '- When Main hall screen divided into 3 parts: Center: 2,240 x 1,260 pixels (16:9) / Branding on both sides: 640 x 1,260 pixels.',
    txt54: 'Inquiry about rental application',
    txt55: '[General rental service]',
    txt56: 'Manager Jung-im Bae',
	  txt56_1: 'Manager Jung-ae Jin',
    txt56_2: 'space@dreamplus.asia',
    txt56_3: '02-520-1506',
    txt57: '[Tenant rental service]',
    txt58: 'Manager Ho-jun Shim',
    txt59: 'Rental Application',
    txt60: `<div class='info-area' data-aos='fade-left' >
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩110,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>16 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Event Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Digital Monitor (50 inches)</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt61: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩110,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>20 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Main Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Digital Monitor (50 inches)</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt62: `<div class='info-area' data-aos='fade-left' data-aos-delay='500'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩110,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>16 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Main Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Digital Monitor (50 inches)</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt63: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩220,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>36 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Main Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Digital Monitor (50 inches)</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt64: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩220,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>40 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Main Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Beam Projector & Screen</li>
          <li>Wireless Mic 2EA</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt65: `<div class='info-area' data-aos='fade-left' data-aos-delay='500'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩165,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>25 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>
            If you are not tenants, you are only able to rent space with Event Hall.
            <br />Single hall rental is not available
          </li>
          <li>Same working hours as Main Hall Rental hours</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Beam Projector & Screen</li>
          <li>Wireless Mic 1EA</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt66: `<div class='info-area'>
    <div class='info-use-header'>
      <span class='fee'>
        <strong>₩385,000</strong> / Hour <span class="vat">(VAT included)</span>
      </span>
    </div>
    <ul class='info-use'>
      <li>
        <h4>Seating capacity</h4>
        <ul class='bullet-square-list'>
          <li>50 persons</li>
        </ul>
      </li>
      <li>
        <h4>Rental Hours</h4>
        <em>Mon-Fri 9:00 AM- 9:00 PM<br />(possible to reserve minimum 2 hours)</em>
        <ul class='bullet-square-list'>
          <li>When Multi Room D and E are rented simultaneously,</li>
          <li class="no-bullet">Non-tenants can rent during weekdays</li>
          <li class="no-bullet">Tenants are able to rent on Sat (9:30 AM - 5:30 PM)</li>
          <li>Not available on official holidays</li>
        </ul>
      </li>
      <li>
        <h4>Available Equipment</h4>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Beam Projector & Screen</li>
          <li>Wireless Mic 2EA</li>
          <li>
            What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
      </li>
    </ul>
  </div>`,
    txt67: 'Rental Application',
    txt68: 'Please apply at least 1 weeks before the date of use for the rental of the Event Hall of DREAMPLUS GANGNAM CENTER .',
    txt69: 'Confirmed',
    txt70: 'Wait for the approval',
    txt71: 'Rejected',
    txt72: 'View Application Details',
    txt73: 'Rental Application',
    // 탭메뉴
    txt74: 'Rental Application',
    txt75: 'History of Event Applications',
    // 진행스탭
    txt76: 'Enter the type of event',
    txt77: 'Select the facility use ',
    txt78: 'Enter the other item',
    txt79: 'Enter the type of event',
    txt80: '<span class="required-mark">*</span> is required field.',
    txt81: 'Name of Event',
    txt82: 'Enter the name of Event',
    txt83: 'You can enter up to 20 characters.',
    txt84: 'Event Introduction',
    txt85: 'Enter event introduction',
    txt86: 'Host/Organizer',
    txt87: 'Enter the Host/Organizer',
    txt88: 'Introduction of event participants',
    txt89: 'Enter introduction of event participants',
    txt90: 'Number of expected participants',
    txt91: 'Enter the number of expected participants',
    txt92: 'Select the facility use ',
    txt93: 'Whether to use microphone',
    txt94: 'Yes (2 units)',
    txt95: 'No',
    txt96: 'Seat setting',
    txt97: 'Table + chair(Maximum 144)',
    txt98: 'Chairs only(Maximum 200)',
    txt99: 'Enter the number of seats required and other request (up to 100 characters)',
    txt100: 'If the required seats are not entered, seats will be prepared for the expected number of participants.',
    txt101: 'If food and catering will be brought in',
    txt102: 'No',
    txt103: 'Yes',
    txt104: 'When bringing in catering, food waste needs to be collected separately (cannot be disposed of in the space)',
    txt105: 'I agree to the terms and conditions for the use of facility.',
    txt106: 'Enter the other item',
    txt107: 'Cell phone number',
    txt108: 'Numbers only',
    txt109: 'Other Inquiry',
    txt110: 'Enter the Inquiry',
    txt111: 'You can enter up to 100 characters.',
    txt112: 'File attachment',
    txt112_1: 'File attachment',
    txt113: 'Search',
    txt114: 'Items of personal information to be collected: Cell phone number of the rental applicant',
    txt115: 'Purpose of collection/use of personal information: Rental apply for DREAMPLUS Gangnam',
    txt116: 'Period of retention and use of personal information',
    txt117: `- For those who opt-in additional information registration, the data is retained until termination (withdrawal) of membership.
    <br />- For non opt-in members, data retained until 7days after the rental day
    <br />`,
    txt118: 'I agree to the collection of personal information for the rental application.',
    txt119: 'Rental application has been completed',
    txt120: `The approval will be answered by e-mail registered within <br />3 to 5 business days from the date of application filed.
    <br />You can view the details of your application in "View Applicatoin Details".`,
    txt121: 'Rental application is not available. ',
    txt122: `Time or place you selected has already been booked,
     or the rental application was not completed due to a temporary technical error.
     Please apply again`,
    txt123: 'Go to the rental application page',

    // 대관신청내역
    txt124: 'If you want to cancel the rental reservation, please contact us by phone at 02-520-1513, 1504.',
    txt125: 'Total',
    txt126: 'cases',
    txt127: 'Date of application',
    txt128: 'more',
    txt129: 'No Results.',

    txt130: 'Lanport',
    txt131: 'Podium',
    txt132: 'Add Table',
    txt133: 'Add Chair',
    txt134: 'Add Projector',
    txt135: 'Whether to use equipment',
    txt136: 'Projector(Except for main hall), Table/Chair(1Person) Multi Room',
    txt137: 'Catering not brought in',
    txt138: 'Use a Microphone',
    txt139: 'Disable Microphone',
    txt140: 'How to make a reservation',
    txt141: 'Check room availability dates',
    txt142: 'Fill in the details on the online application form',
    txt143: 'Receive the reservation confirmation by email',
    txt144: 'Payment for Reservation',
    txt145: 'Take a guide of facility use',
    txt146: 'Tidy up the room before leaving',

    // 공간 추가
    txt147: 'MAIN HALL',
    txt148: 'MULTI HALL',
    txt149: 'EVENT HALL',
    txt150: 'MULTI ROOM',
    txt151: 'EXECUTIVE ROOM',
    txt152: 'MEETING ROOM',
    txt153: 'DRAWING LOUNGE',
    txt154: `
    <li>144 seats with tables</li>
    <li>180 seats without table</li>`,
    txt155: `
    <li>Possible to reserve minimum 2 hours</li>
    <li>30 minutes before/after the event are provided free of charge</li>`,
    txt155_2: `
    <li>Possible to reserve minimum 2 hours</li>`,
    txt155_3: `
    <li>Possible to reserve minimum 2 hours</li>
    <li>Schedule with start/end time (not provided for 30 minutes before/after)</li>
    `,
    txt156: `
      <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class='i_eventhall project'></i>
            <p class='txt'>Large LED<br/>Screen</p>
          </li>
          <li>
            <i class='i_eventhall speak'></i>
            <p class='txt'>Speaker</p>
          </li>
          <li>
            <i class='i_eventhall mic'></i>
            <p class='txt'>Wireless mic<br/>(2 provided)</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>Large LED screen resolution: (1920X1080), supports laptop content output and automatic full-size resizing.</li>
          <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
        </ul>
      </div>
    `,
    txt156_1: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>Large LED<br/>Screen</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>Speaker</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>Wireless mic<br/>(4 provided)</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>Large LED Screen Resolution: (3520x1260) Wide screen, can be divided into 3 parts for branding on both sides.<br/>
        ※ When divided into 3 parts: Center: 2,240 x 1,260 pixels (16:9) / Branding on both sides: 640 x 1,260 pixels.
        </li>
        <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
      </ul>
    </div>
  `,
  txt156_2: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class="i_eventhall beam"></i>
          <p class="txt">Beam Project</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>Screen</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>Wireless mic<br/>(2 provided)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_pinmic'></i>
          <p class='txt'>1 Microphone</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_podium'></i>
          <p class='txt'>Podium</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>Speaker</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>5M HDMI Cable</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>5200ANSI, WUXGA(Resolution : 1920*1200 pixel)</li>
        <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
      </ul>
    </div>
  `,
  txt156_4: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>Furniture<br/>(for 10 people)</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>Available for booking together with Executive Room #1 (folding door partition)</li>
      </ul>
    </div>
  `,
  txt156_3: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall monitor'></i>
          <p class='txt'>85inch TV (4K)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_remote'></i>
          <p class='txt'>Remote Control</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>Furniture<br/>(for 8 people)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
      <li>Available for booking together with Executive Room #2 (folding door partition)</li>
      </ul>
    </div>
  `,
  txt156_5: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_table'></i>
          <p class='txt'>Table + Chairs</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>The rental space can be extended by combining it with the Drawing Lounge.</li>
      </ul>
    </div>
  `,
  txt156_6: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall ico_5f_sofa'></i>
          <p class='txt'>Sofas and <br/>additional <br/>furniture.</p>
        </li>
        <li>
          <i class='i_eventhall speak'></i>
          <p class='txt'>Speaker</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
        <li>
          <i class='i_eventhall mic'></i>
          <p class='txt'>Wireless mic<br>(2 provided)</p>
        </li>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall project'></i>
          <p class='txt'>Screen</p>
        </li>
        <li>
          <i class="i_eventhall beam"></i>
          <p class="txt">Beam Project</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>The rental space can be extended by combining it with the Meeting Room.</li>
        <li>5200ANSI, WUXGA(Resolution : 1920*1200 pixel)</li>
        <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
      </ul>
    </div>
  `,
  txt156_7: `
    <div class='icons_list'>
      <ul class='icons_track'>
        <li>
          <i class='i_eventhall wifi'></i>
          <p class='txt'>Wireless<br/>WIFI</p>
        </li>
        <li>
          <i class='i_eventhall monitor'></i>
          <p class='txt'>85inch TV (4K)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_remote'></i>
          <p class='txt'>Remote <br/>Control</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_pocket'></i>
          <p class='txt'>Pocket <br/>Pool <br/>Table</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_furniture'></i>
          <p class='txt'>Furniture<br/>(for 6-8 people)</p>
        </li>
        <li>
          <i class='i_eventhall ico_5f_hdmi'></i>
          <p class='txt'>HDMI</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="list-caution">
        <li>Please consult with the manager in advance if you plan to use the pocket pool during your rental</li>
      </ul>
    </div>
  `,
    txt157: `
      <li>
      If you are not tenants, you are only able to rent space with Event Hall.
      <br />Single hall rental is not available
      </li>
      <li>Same working hours as Main Hall Rental hours</li>
    `,
    txt158: `
       <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class="i_eventhall monitor"></i>
            <p class="txt">Digital Monitor<br/>(50 inches)</p>
          </li>
          <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">Speaker</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
        </ul>
      </div>
    `,
    txt158_7: `
       <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class="i_eventhall monitor"></i>
            <p class="txt">Digital Monitor<br/>(50 inches)</p>
          </li>
          <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">Speaker</p>
          </li>
          <li>
            <i class="i_eventhall mic"></i>
            <p class="txt">Wireless mic<br/>(1 provided)</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
        </ul>
      </div>
    `,
    txt159: `
      <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">Beam Project</p>
          </li>
          <li>
            <i class="i_eventhall project"></i>
            <p class="txt">Screen</p>
          </li>
        <li>
            <i class="i_eventhall mic"></i>
            <p class="txt">Wireless mic<br/>(2 provided)</p>
        </li>
        <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">Speaker</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
        </ul>
      </div>
    `,
    txt160: `
     <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class="i_eventhall wifi"></i>
            <p class="txt">Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">Beam Project</p>
          </li>
          <li>
            <i class="i_eventhall project"></i>
            <p class="txt">Screen</p>
          </li>
        <li>
            <i class="i_eventhall mic"></i>
            <p class="txt">Wireless mic<br/>(1 provided)</p>
        </li>
        <li>
            <i class="i_eventhall speak"></i>
            <p class="txt">Speaker</p>
          </li>
        </ul>
      </div>
      <div>
        <ul class="list-caution">
          <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
        </ul>
      </div>
    `,
    txt161: 'Rental Procedure',
    txt162: 'Check the availability',
    txt163: 'Reservation Request',
    txt164: `Please select start/finish time from reservation available time after searching the date you want to use.
    You should apply for the space rent at least 1 weeks in advance within 2 months. `,
    txt165: `Rental application form
    and the terms and condition for use`,
    txt166: 'Proceed with reservation through the online form.',
    txt167: 'Please fill out the rental application and agree to the terms and conditions for use according to the online form. If the event is not related to startup and ecosystem, and if participation of DREAMPLUS tenants is restricted, rental service may be restricted.',
    txt168: 'Approval of application',
    txt169: 'Check approval status via email.',
    txt170: 'After reviewing the details of the rental application you have completed, you will be notified if it is approved by replying your e-mail of application..',
    txt171: 'Payment',
    txt172: 'Payment for Reservation',
    txt173: `If your application is approved, it will be notified by e-mail how to pay
    (Reservation fee- 20% of the total rental fee).
    After confirming the payment,
    the deposit is not refundable. If the balance is not paid 5 business days prior to the reserved date, it is considered as cancellation.
    Once paid, it is impossible to cancel the reservation or refund.`,
    txt173_1: `
    If payment is not made by 5 business days before the rental date, the reservation will be considered canceled. Once payment is made, cancellations and refunds of the total payment amount are not possible.
    A tax invoice will be issued after payment.
    `,
    txt174: 'Usage of Room',
    txt175: 'Take a guide of facility use',
    txt176: 'You are able to use the space after receiving prior education for using the venue on the same day of event.',
    txt177: 'Exit after facility inspection',
    txt178: 'Tidy up the room before leaving',
    txt179: `
    In the event of damage to the facility or loss of goods, the liability for damages shall arise,
     and the amount charged for such damages shall be paid within<br /> 10 business days of the billing date.
    `,
    txt179_1: `
    Furniture cannot be moved within the rental space, except for chairs in EVENT HALL #1 and #2, which can be returned to their original position after moving. 
    `,
    txt180: 'Please make sure to check the content!',
    txt181: `
    The following is a summary of the main matters, and please refer to,
    <a href="#" @click.prevent="onFacilitiesPopup">
      <strong> [the terms and conditions for use of facilities]</strong>
    </a> for more details that can be available at the time of rental application.
    `,
    txt181_1: 'The following is a summary of the main matters, and please refer to,',
    txt181_2: '<strong> [the terms and conditions for use of facilities]</strong>',
    txt181_3: ' for more details that can be available at the time of rental application.',
    txt182: 'Free Parking: 1 free parking space will be provided only for vehicles of the event organizers for main hall rentals (Request needed after entering on the event day).',
    txt183: 'Paid parking is available for participants (₩6,000/hour), However, parking may not be available sometimes, public transportation is recommended.',
    txt184: 'Seating capacity',
    txt184_2: 'persons',
    txt185: 'Rental Hours',
    txt186: 'Mon-Fri',
    txt187: 'Sat-Sun',
    txt188: '₩605,000 / Hour (VAT included)',
    txt189: '₩220,000 / Hour (VAT included)',
    txt190: '₩330,000 / Hour (VAT included)',
    txt190_1: '₩154,000 / Hour (VAT included)',
    txt191: '₩990,000 / Hour (VAT included)',
    txt192: '₩110,000 / Hour (VAT included)',
    txt193: '₩220,000 / Hour (VAT included)',
    txt194: '₩165,000 / Hour (VAT included)',
    txt195: '₩385,000 / Hour (VAT included)',
    txt196: '₩154,000 / Hour (VAT included)',
    txt197: 'Information',
    txt198: 'Price',
    txt199: `Facilities 
    <br/>/ Equipment`,
    txt200: 'Location Detail',

    alertMsg: {
      msg1: 'Please drag and select the time you want',
      msg2: 'You have already applied<br/>Duplicate application is not possible.',
      msg3: 'Please move the previous page if you wnat to make additional rental application.',
      msg4: 'Please check the file size. File size exceeded.',
      msg5: 'All information entered will be deleted<br/>Would you like to cancel your rental application?',
      msg6: '',
    },
  },
  MobileEventReservation: {
    txt1: `<ul class='info-use'>
             <li><strong class='title'><strong>₩605,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Capacity(100PY)</strong>
                <ul class='bullet-square-list'>
                  <li>144 seats with tables</li>
                  <li>180 seats without table</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />
                Sat-Sun 9:30 AM- 6:00 PM <br />
                (possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>Not available on official holidays <br />
                   <span class='asterisk-mark'>Tenants are able to rent on Sat (9:30 AM - 5:30 PM)</span>
                  </li>
                  <li>Rentals are available for at least 2 hours, and 30 minutes before/after the event are provided free of charge.
                    <br />(If more time is needed than given free time, please include it in the rental application)
                  </li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li><li>Large LED Screen Resolution: (3520x1260) Wide screen, can be divided into 3 parts for branding on both sides.<br/>
                  ※ When divided into 3 parts: Center: 2,240 x 1,260 pixels (16:9) / Branding on both sides: 640 x 1,260 pixels.
                  </li>
                  <li>Wireless mic (4 provided)</li>
                  <li>MacBook and 4K resolution connection unavailable (Please bring compatible adapters such as HDMI cable, etc., if necessary).</li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                 <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Large LED Screen</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (4 provided)</p>
                </li>
              </ul>
            </div>`,
    txt2: `<ul class='info-use'> <!--  Multi Room A-->
             <li><strong class='title'><strong>₩110,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>16 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Digital Monitor (50 inches)</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>Digital Monitor</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (1 provided)</p>
                </li>
              </ul>
            </div>`,
    txt3: `<ul class='info-use'> <!--  Multi Room B-->
             <li><strong class='title'><strong>₩110,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>16 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Digital Monitor (50 inches)</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                <li>
                  <i class='i_eventhall monitor'></i>
                  <p class='txt'>Digital Monitor</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (1 provided)</p>
                </li>
              </ul>
            </div>`,
    txt4: `<ul class='info-use'> <!--  Multi Room C-->
              <li><strong class='title'><strong>₩110,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>12 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Digital Monitor (50 inches)</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Digital Monitor</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (1 provided)</p>
                </li>
              </ul>
            </div>`,
    txt5: `<ul class='info-use'> <!--  Multi Room B + C-->
              <li><strong class='title'><strong>₩220,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>28 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Digital Monitor (50 inches)</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
                <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Digital Monitor</p>
                </li>
                 <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (1 provided)</p>
                </li>
              </ul>
            </div>`,
    txt6: `<ul class='info-use'> <!--  Multi Room D-->
              <li><strong class='title'><strong>₩220,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>40 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Beam Projector & Screen</li>
                  <li>Wireless mic (2 provided)</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
              <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>Beam Project</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Screen</p>
                </li>
                <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (2 provided)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
               </li>
              </ul>
            </div>`,
    txt7: `<ul class='info-use'> <!--  Multi Room E-->
              <li><strong class='title'><strong>₩165,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>25 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Beam Projector & Screen</li>
                  <li>Wireless Mic 1EA</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
              <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>Beam Project</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Screen</p>
                </li>
               <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (1 provided)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
               </li>
              </ul>
            </div>`,
    txt8: `<ul class='info-use'> <!--  Multi Room E-->
              <li><strong class='title'><strong>₩385,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
              <li>
                <strong class='title'>Seating capacity</strong>
                <ul class='bullet-square-list'>
                  <li>60 persons</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Rental Hours</strong>
                <em>Mon-Fri 9:00 AM- 9:00 PM <br />(possible to reserve minimum 2 hours)</em>
                <ul class='bullet-square-list'>
                  <li>If you are not tenants, you are only able to rent space with Event Hall.
                    <br />Single hall rental is not available</li>
                  <li>Same working hours as Event Hall Rental hours</li>
                  <li>Not available on official holidays</li>
                </ul>
              </li>
              <li>
                <strong class='title'>Available Equipment</strong>
                <ul class='bullet-square-list'>
                  <li>Wireless WIFI</li>
                  <li>Beam Projector & Screen</li>
                  <li>Wireless Mic 1EA</li>
                  <li>
                   What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
                   clicker (pointer), compatible gender changer except for HDMI cable
                  </li>
                </ul>
              </li>
            </ul>
            <!-- 위항목에 일치하는 아이콘 노출 -->
            <div class='icons_list'>
              <ul class='icons_track'>
              <li>
                  <i class='i_eventhall wifi'></i>
                  <p class='txt'>Wireless WIFI</p>
                </li>
                 <li>
                  <i class='i_eventhall beam'></i>
                  <p class='txt'>Beam Project</p>
                </li>
                <li>
                  <i class='i_eventhall project'></i>
                  <p class='txt'>Screen</p>
                </li>
               <li>
                  <i class='i_eventhall mic'></i>
                  <p class='txt'>Wireless mic (2 provided)</p>
                </li>
               <li>
                  <i class='i_eventhall speak'></i>
                  <p class='txt'>Speaker</p>
               </li>
              </ul>
            </div>`,
    txt09: 'Please apply at least 1 weeks before the date of use for the rental of the Event Hall',
    txt10: 'Information Use',
    txt11: 'How to use',
    txt12: 'Start Time',
    txt13: 'End Time',
    txt14: 'Confirm',
    txt15: 'Terms of Service',
    txt16: 'Reserved Space',
    txt17: 'Reservation Date',
    txt18: `<ul class='info-use'> <!--  EVENT HALL #1-->
            <li><strong class='title'><strong>₩330,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
            <li>
              <strong class='title'>Seating capacity</strong>
              <ul class='bullet-square-list'>
                <li>40 persons</li>
              </ul>
            </li>
            <li>
              <strong class='title'>Rental Hours</strong>
              <em>Mon-Fri 9:00 AM- 09:00 PM<br />
              ※ Possible to reserve minimum 2 hours<br/>
              ※ Schedule with start/end time (not provided for 30 minutes before/after)
              </em>
            </li>
            <li>
              <strong class='title'>Available Equipment</strong>
              <ul class='bullet-square-list'>
                <li>Wireless WIFI</li>
                <li>Beam Projector</li>
                <li>Screen</li>
                <li>Wireless mic</li>
                <li>1 Microphone</li>
                <li>Podium</li>
                <li>Speaker</li>
                <li>5M HDMI Cable</li>
                <li>5200ANSI, WUXGA(Resolution : 1920*1200 pixel)</li>
                <li>
                What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), <br/>compatible gender changer except for HDMI cable
                </li>
              </ul>
            </li>
          </ul>
          <!-- 위항목에 일치하는 아이콘 노출 -->
          <div class='icons_list'>
            <ul class='icons_track'>
            <li>
              <i class='i_eventhall wifi'></i>
              <p class='txt'>Wireless<br/>WIFI</p>
            </li>
            <li>
              <i class="i_eventhall beam"></i>
              <p class="txt">Beam Project</p>
            </li>
            <li>
              <i class='i_eventhall project'></i>
              <p class='txt'>Screen</p>
            </li>
            <li>
              <i class='i_eventhall mic'></i>
              <p class='txt'>Wireless mic</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_pinmic'></i>
              <p class='txt'>1 Microphone</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_podium'></i>
              <p class='txt'>Podium</p>
            </li>
            <li>
              <i class='i_eventhall speak'></i>
              <p class='txt'>Speaker</p>
            </li>
            <li>
              <i class='i_eventhall ico_5f_hdmi'></i>
              <p class='txt'>5M HDMI Cable</p>
            </li>
            </ul>
          </div>`,
        txt19: `<ul class='info-use'> <!--  EVENT HALL #2-->
          <li><strong class='title'><strong>₩330,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
          <li>
            <strong class='title'>Seating capacity</strong>
            <ul class='bullet-square-list'>
              <li>40 persons</li>
            </ul>
          </li>
          <li>
            <strong class='title'>Rental Hours</strong>
            <em>Mon-Fri 9:00 AM- 09:00 PM<br />
            ※ Possible to reserve minimum 2 hours<br/>
            ※ Schedule with start/end time (not provided for 30 minutes before/after)
            </em>
          </li>
          <li>
            <strong class='title'>Available Equipment</strong>
            <ul class='bullet-square-list'>
              <li>Wireless WIFI</li>
              <li>Beam Projector</li>
              <li>Screen</li>
              <li>Wireless mic</li>
              <li>1 Microphone</li>
              <li>Podium</li>
              <li>Speaker</li>
              <li>5M HDMI Cable</li>
              <li>5200ANSI, WUXGA(Resolution : 1920*1200 pixel)</li>
              <li>
              What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable), clicker (pointer), <br/>compatible gender changer except for HDMI cable
              </li>
            </ul>
          </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
          <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">Beam Project</p>
          </li>
          <li>
            <i class='i_eventhall project'></i>
            <p class='txt'>Screen</p>
          </li>
          <li>
            <i class='i_eventhall mic'></i>
            <p class='txt'>Wireless mic</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_pinmic'></i>
            <p class='txt'>1 Microphone</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_podium'></i>
            <p class='txt'>Podium</p>
          </li>
          <li>
            <i class='i_eventhall speak'></i>
            <p class='txt'>Speaker</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_hdmi'></i>
            <p class='txt'>5M HDMI Cable</p>
          </li>
          </ul>
        </div>`,
        txt20: `<ul class='info-use'> <!-- Multi ROOM 5f -->
        <li><strong class='title'><strong>₩220,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
        <li>
          <strong class='title'>Seating capacity</strong>
          <ul class='bullet-square-list'>
            <li>6 persons</li>
          </ul>
        </li>
        <li>
          <strong class='title'>Rental Hours</strong>
          <em>Mon-Fri 9:00 AM- 09:00 PM<br />
        ※ Possible to reserve minimum 2 hours<br/>
        ※ Schedule with start/end time (not provided for 30 minutes before/after)
        </em>
        </li>
        <li>
          <strong class='title'>Available Equipment</strong>
          <ul class='bullet-square-list'>
            <li>Wireless WIFI</li>
            <li>85inch TV (4K)</li>
            <li>Remote Control</li>
            <li>Pocket Pool Table</li>
            <li>Furniture(for 6-8 people)</li>
            <li>HDMI</li>
          </ul>
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class='i_eventhall monitor'></i>
            <p class='txt'>85inch TV (4K)</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_remote'></i>
            <p class='txt'>Remote <br/>Control</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_pocket'></i>
            <p class='txt'>Pocket <br/>Pool <br/>Table</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_furniture'></i>
            <p class='txt'>Furniture<br/>(for 6-8 people)</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_hdmi'></i>
            <p class='txt'>HDMI</p>
          </li>
        </ul>
        </div>`,
        txt21: `<ul class='info-use'> <!-- EXECUTIVE ROOM #1 -->
        <li><strong class='title'><strong>₩154,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
        <li>
        <strong class='title'>Seating capacity</strong>
        <ul class='bullet-square-list'>
        <li>8 persons</li>
        </ul>
        </li>
        <li>
        <strong class='title'>Rental Hours</strong>
        <em>Mon-Fri 9:00 AM- 09:00 PM<br />
        ※ Possible to reserve minimum 2 hours<br/>
        ※ Schedule with start/end time (not provided for 30 minutes before/after)
        </em>
        </li>
        <li>
        <strong class='title'>Available Equipment</strong>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>85inch TV (4K)</li>
          <li>Remote Control</li>
          <li>Furniture<br/>(for 8 people)</li>
          <li>HDMI</li>
          <li> 
          Available for booking together with Executive Room #2 (folding door partition)
          </li>
        </ul>
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class='i_eventhall monitor'></i>
            <p class='txt'>85inch TV (4K)</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_remote'></i>
            <p class='txt'>Remote Control</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_furniture'></i>
            <p class='txt'>Furniture<br/>(for 8 people)</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_hdmi'></i>
            <p class='txt'>HDMI</p>
          </li>
        </ul>
        </div>`,
        txt22: `<ul class='info-use'> <!-- EXECUTIVE ROOM #2 -->
        <li><strong class='title'><strong>₩330,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
        <li>
        <strong class='title'>Seating capacity</strong>
        <ul class='bullet-square-list'>
          <li>10 persons</li>
        </ul>
        </li>
        <li>
        <strong class='title'>Rental Hours</strong>
        <em>Mon-Fri 9:00 AM- 09:00 PM<br />
        ※ Possible to reserve minimum 2 hours<br/>
        ※ Schedule with start/end time (not provided for 30 minutes before/after)
        </em>
        </li>
        <li>
        <strong class='title'>Available Equipment</strong>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Furniture (for 10 people)</li>
          <li> 
          Available for booking together with Executive Room #1 (folding door partition)
          </li>
        </ul>
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless WIFI</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_furniture'></i>
            <p class='txt'>Furniture<br/>(for 10 people)</p>
          </li>
        </ul>
        </div>`,        
        txt23: `<ul class='info-use'> <!-- Meeting ROOM -->
        <li><strong class='title'><strong>₩154,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
        <li>
        <strong class='title'>Seating capacity</strong>
        <ul class='bullet-square-list'>
          <li>10 persons</li>
        </ul>
        </li>
        <li>
        <strong class='title'>Rental Hours</strong>
        <em>Mon-Fri 9:00 AM- 09:00 PM<br />
        ※ Possible to reserve minimum 2 hours<br/>
        ※ Schedule with start/end time (not provided for 30 minutes before/after)
        </em>
        </li>
        <li>
        <strong class='title'>Available Equipment</strong>
        <ul class='bullet-square-list'>
          <li>Wireless WIFI</li>
          <li>Table + Chairs</li>
          <li>The rental space can be extended by combining it with the Drawing Lounge.</li>
        </ul>
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_table'></i>
            <p class='txt'>Table + Chairs</p>
          </li>
        </ul>
        </div>`,
        txt24: `<ul class='info-use'> <!-- LOUNGE -->
        <li><strong class='title'><strong>₩990,000</strong> / Hour <span class='vat'>(VAT included)</span></strong></li>
        <li>
        <strong class='title'>Seating capacity</strong>
        <ul class='bullet-square-list'>
        <li>40 persons</li>
        </ul>
        </li>
        <li>
        <strong class='title'>Rental Hours</strong>
        <em>Mon-Fri 9:00 AM- 09:00 PM<br />
        ※ Possible to reserve minimum 2 hours<br/>
        ※ Schedule with start/end time (not provided for 30 minutes before/after)
        </em>
        </li>
        <li>
        <strong class='title'>Available Equipment</strong>
        <ul class='bullet-square-list'>
          <li>Sofas and additional furniture.</li>
          <li>Speaker</li>
          <li>HDMI</li>
          <li>Wireless mic (2ea)</li>
          <li>Wireless WIFI</li>
          <li>Screen</li>
          <li>Beam Project</li>  
          <li>
          What you have to bring if needed : Laptop (MacBook, 4K resolution not connectable),
          clicker (pointer), compatible gender changer except for HDMI cable
          </li>
        </ul>
        </li>
        </ul>
        <!-- 위항목에 일치하는 아이콘 노출 -->
        <div class='icons_list'>
        <ul class='icons_track'>
          <li>
            <i class='i_eventhall ico_5f_sofa'></i>
            <p class='txt'>Sofas and <br/>additional <br/>furniture.</p>
          </li>
          <li>
            <i class='i_eventhall speak'></i>
            <p class='txt'>Speaker</p>
          </li>
          <li>
            <i class='i_eventhall ico_5f_hdmi'></i>
            <p class='txt'>HDMI</p>
          </li>
          <li>
            <i class='i_eventhall mic'></i>
            <p class='txt'>Wireless mic</p>
          </li>
          <li>
            <i class='i_eventhall wifi'></i>
            <p class='txt'>Wireless<br/>WIFI</p>
          </li>
          <li>
            <i class='i_eventhall project'></i>
            <p class='txt'>Screen</p>
          </li>
          <li>
            <i class="i_eventhall beam"></i>
            <p class="txt">Beam Project</p>
          </li>
        </ul>
        </div>`,
  },
  Legal: {
    Facilities: {
      txt01: 'The terms and condition for use', // 시설물 사용약관
      txt02: 'Article 1 (Purpose)',
      txt03: `The agreement aims to stipulate matters necessary to collect fees from a person who intends
       to use the facility of DREAMPLUS Gangnam Center (hereinafter to be referred to as "User)
       and to handle facility maintenance, and matters to be observed if User uses the facility.`,
      txt04: 'Article 2 (Definition of Terms)',
      txt05: `Terms used in this agreement shall be defined as below.
      <br />① "Provider" means Hanwha Life Insurance Co., Ltd.
      <br />② "User" means a person who uses facility within a particular scope following specific methods
       after obtaining approval for use from Provider.
      <br />③ "Facility" means buildings and auxiliary facilities in DREAMPLUS Gangnam Center
       that User can use after acquiring the approval for use from Provider.
       Specific scope of Facility is stipulated in Article 3.
      <br />④ "Fee" means the amount that User pays to Provider for using Facility.
      <br />`,
      txt06: 'Article 3 (Scope of Facility)',
      txt07: `① Scope of facility use (rental) of User is as follows.
      <br />1. Event Hall (B1F), wireless mic, sound, digital screen and other equipment, and chair, table and other furniture
      <br />2. Additional facilities that Provider allows User to use (including Multi Room)
      <br />② Provider may put restriction on part of scope of facility use depending on the maintenance and other issues.
      <br />`,
      txt08: 'Article 4 (Application for Use and Approval)',
      txt09: `① Person who wants to use Facility shall check with Provider regarding the available date and submit application
       for use of facility (hereinafter to be referred to as "Application") to Provider.
       However, if Provider considers it unnecessary in operation, the submission of Application can be waivered.
       <br />② User shall submit Application as per Article 1 at least 1 weeks before the expected date of use.
      <br />③ Provider may give approval for facility use after examining Application stipulated in Article 1,
       if it does not affect the facility management and there is no schedule conflict. However,
       if Application belongs to reasons of limited use of Facility as per Article 8, approval for use can be restricted.
      <br />`,
      txt10: 'Article 5 (Payment of Fee)',
      txt11: `① User must support the reservation transfer fee (hereinafter referred to as the “reservation fee”)
       and the facility usage fee in cash (transfer to an account designated by the provider) to the provider.
       <br />② User must provide 20% of the facility usage fee to the provider as a deposit on 5 days (business days)
       after the submission of the application form and approval according to the fourth rule. Will be canceled.
       <br />③ Post-sales support fee as stated in paragraph 2 must be supported within 5 days from the date of use.
       (Work day standard)
       <br />④ 5 If the fee is not paid on the delivery date according to the provision of paragraph 3,
       it is considered as a large cancellation,
       the reservation can be canceled and the deposit will not be returned.
       (In the case of less than 5 days of use date,
       deposit is required on a separately proposed schedule)
       <br />⑤ Notwithstanding the provision of paragraph 3, when it is decided to support the fee for use after the event through prior consultation,
       it is necessary to pay the fee properly on the discussed delivery date, and if it has not been delivered,
       The user pays by adding an amount calculated by adding an extension fee based on an annual interest rate of 19% of the amount received.
       However, if the delivery date is a financial institution holiday, the delivery date is considered to have been extended by the next business day.
      <br />`,
      txt12: 'Article 6 (Use change, cancellation of application and refund)',
      txt13: `① If the user changes the usage (time, facilities, etc.) or cancels the application,
       please submit a request for cancellation or a written request for cancellation in writing.
      <br />② If the user cancels the usage plan for the facility,
       the deposit paid under the provision of paragraph 5 will be regarded as a penalty and will not be refunded.
      <br />③ Changes in usage details and cancellation requests can be made until the usage fee payment date based on Article 5.3, and after that date,
       usage fees cannot be changed or refunded due to cancellation.
      <br />④ If the user can not use the facility on the day of use (event day) for the reason (* natural disaster) of the 7th paragraph,
       the provider does not take responsibility for the user,
       the 5th paragraph 2 and 3 We will return the high deposit and usage fees that have been filed based on terms.
      <br />`,
      txt14: `Article 7 (Limits of Facility Use)
       Provider may prohibit or put restriction on User's facility use as follows`,
      txt15: `① If User violates these regulations or instructions necessary for operation and management of Facility owned by Provider
      <br />② If purpose or method of Facility use is inconsistent with operation purpose of DREAMPLUS Gangnam Center or there is a preceeding reservation made
      <br />③ If Facility use cause serious harm to Facility management, or Facility is not available for use due to Force Majeure such as natural disasters
      <br />`,
      txt16: 'Article 8 (User\'s Liability and Compensation for Damage)',
      txt17: `① User shall make its best effort to use Facility and auxiliary facilities in a safe and clean manner during the period of use.
       Without Provider's consent, User shall not let others use Facility that User obtained approval to use.
       <br />② User shall take the greatest possible care not to bring food other than drink in Event Hall.
       However, if there is prior consultation with Provider,
        User is allowed to bring food including catering food in Event Hall and meeting rooms.
       After the event, User shall directly collect and discard food waste on its own. For other wastes generated,
       User shall sort out them out for recycling and discard them at a designated area.
       <br />③ If User intentionally or negligently cause destruction, damage or loss to Facility or auxiliary facility,
       User is liable for compensation for damage such as compensation with the same item or restitution.
       If User does not take the liability by making compensation with same product or conducting restoration to the original state,
       User shall pay the equivalent amount within ten (10) working days after the date of notification of amount of damages sent by Provider.
       <br />④ User shall not carry inflammable materials or items that can cause a fire accident into the Facility.
       User shall be liable for damage occurring from any violation.
       <br />⑤ User is responsible for managing its employees, participants of the event or meeting, or consigned trainees who use the Facility.
       If a safety accident occurred due to violation of duty of care by persons who actually use Facility, User is responsible for it.
       <br />⑥ If User intends to install special equipment or structure within the period of use,
       User shall specify it in the Application and obtain prior approval from Provider.
        User takes responsibility of removing installations and conducting restoration to the original state at a time when the period of use is over.
      <br />`,
      txt18: 'Article 9 (Others)',
      txt19: `If there is any dispute about matters not specified in this agreement or interpretation of this Agreement,
       Provider and User shall make decision by agreement.
        If an agreement is not reached, the case shall be followed in accordance with law related to regulations of this agreement,
         civil law, commercial law and other relevant laws and fair and reasonable ordinary practices.
      <br />`,
    },
    Reservation: {
      txt01: 'Article 1 (Purpose)',
      txt02: `The agreement aims to stipulate matters necessary to collect fees from a person who intends
       to use the facility of DREAMPLUS Gangnam Center (hereinafter to be referred to as "User)
       and to handle facility maintenance, and matters to be observed if User uses the facility.`,
      txt03: 'Article 2 (Definition of Terms)',
      txt04: `Terms used in this agreement shall be defined as below.
      <br />① "Provider" means Hanwha Life Insurance Co., Ltd.
      <br />② "User" means a person who uses facility within a particular scope following specific methods
       after obtaining approval for use from Provider.
      <br />③ "Facility" means buildings and auxiliary facilities in DREAMPLUS Gangnam Center
       that User can use after acquiring the approval for use from Provider.
       Specific scope of Facility is stipulated in Article 3.
      <br />④ "Fee" means the amount that User pays to Provider for using Facility.
      <br />`,
      txt05: 'Article 3 (Scope of Facility)',
      txt06: `① Scope of facility use (rental) of User is as follows.
      <br />1. Event Hall (B1F), wireless mic, sound, digital screen and other equipment, and chair, table and other furniture
      <br />2. Additional facilities that Provider allows User to use (including Multi Room)
      <br />② Provider may put restriction on part of scope of facility use depending on the maintenance and other issues.
      <br />`,
      txt07: 'Article 4 (Application for Use and Approval)',
      txt08: `① Person who wants to use Facility shall check with Provider regarding the available date and submit application
       for use of facility (hereinafter to be referred to as "Application") to Provider.
       However, if Provider considers it unnecessary in operation, the submission of Application can be waivered.
       <br />② User shall submit Application as per Article 1 at least 1 weeks before the expected date of use.
       <br />③ Provider may give approval for facility use after examining Application stipulated in Article 1,
       if it does not affect the facility management and there is no schedule conflict. However,
       if Application belongs to reasons of limited use of Facility as per Article 8, approval for use can be restricted.
      <br />`,
      txt09: 'Article 5 (Payment of Fee)',
      txt10: `① User must support the reservation transfer fee (hereinafter referred to as the “reservation fee”)
       and the facility usage fee in cash (transfer to an account designated by the provider) to the provider.
       <br />② User must provide 20% of the facility usage fee to the provider as a deposit on 5 days (business days)
       after the submission of the application form and approval according to the fourth rule. Will be canceled.
       <br />③ Post-sales support fee as stated in paragraph 2 must be supported within 5 days from the date of use.
       (Work day standard)
       <br />④ 5 If the fee is not paid on the delivery date according to the provision of paragraph 3,
       it is considered as a large cancellation,
       the reservation can be canceled and the deposit will not be returned.
       (In the case of less than 5 days of use date,
       deposit is required on a separately proposed schedule)
       <br />⑤ Notwithstanding the provision of paragraph 3, when it is decided to support the fee for use after the event through prior consultation,
       it is necessary to pay the fee properly on the discussed delivery date, and if it has not been delivered,
       The user pays by adding an amount calculated by adding an extension fee based on an annual interest rate of 19% of the amount received.
       However, if the delivery date is a financial institution holiday, the delivery date is considered to have been extended by the next business day.
      <br />`,
      txt11: 'Article 6 (Use change, cancellation of application and refund)',
      txt12: `① If the user changes the usage (time, facilities, etc.) or cancels the application,
       please submit a request for cancellation or a written request for cancellation in writing.
       <br />② If the user cancels the usage plan for the facility,
       the deposit paid under the provision of paragraph 5 will be regarded as a penalty and will not be refunded.
      <br />③ Changes in usage details and cancellation requests can be made until the usage fee payment date based on Article 5.3,
       and after that date, usage fees cannot be changed or refunded due to cancellation.
      <br />④ If the user can not use the facility on the day of use (event day) for the reason (* natural disaster) of the 7th paragraph,
       the provider does not take responsibility for the user,
       the 5th paragraph 2 and 3 We will return the high deposit and usage fees that have been filed based on terms.
      <br />`,
      txt13: `Article 7 (Limits of Facility Use)
       Provider may prohibit or put restriction on User's facility use as follows`,
      txt14: `① If User violates these regulations or instructions necessary for operation and management of Facility owned by Provider
      <br />② If purpose or method of Facility use is inconsistent with operation purpose of DREAMPLUS Gangnam Center or there is a preceeding reservation made
      <br />③ If Facility use cause serious harm to Facility management, or Facility is not available for use due to Force Majeure such as natural disasters
      <br />`,
      txt15: 'Article 8 (User\'s Liability and Compensation for Damage)',
      txt16: `① User shall make its best effort to use Facility and auxiliary facilities in a safe and clean manner during the period of use.
       Without Provider's consent, User shall not let others use Facility that User obtained approval to use.
       <br />② User shall take the greatest possible care not to bring food other than drink in Event Hall.
       However, if there is prior consultation with Provider,
        User is allowed to bring food including catering food in Event Hall and meeting rooms.
       After the event, User shall directly collect and discard food waste on its own. For other wastes generated,
       User shall sort out them out for recycling and discard them at a designated area.
       <br />③ If User intentionally or negligently cause destruction, damage or loss to Facility or auxiliary facility,
       User is liable for compensation for damage such as compensation with the same item or restitution.
       If User does not take the liability by making compensation with same product or conducting restoration to the original state,
       User shall pay the equivalent amount within ten (10) working days after the date of notification of amount of damages sent by Provider.
       <br />④ User shall not carry inflammable materials or items that can cause a fire accident into the Facility.
       User shall be liable for damage occurring from any violation.
       <br />⑤ User is responsible for managing its employees, participants of the event or meeting, or consigned trainees who use the Facility.
       If a safety accident occurred due to violation of duty of care by persons who actually use Facility, User is responsible for it.
       <br />⑥ If User intends to install special equipment or structure within the period of use,
       User shall specify it in the Application and obtain prior approval from Provider.
        User takes responsibility of removing installations and conducting restoration to the original state at a time when the period of use is over.
      <br />`,
      txt17: 'Article 9 (Others)',
      txt18: `If there is any dispute about matters not specified in this agreement or interpretation of this Agreement,
       Provider and User shall make decision by agreement.
        If an agreement is not reached, the case shall be followed in accordance with law related to regulations of this agreement,
         civil law, commercial law and other relevant laws and fair and reasonable ordinary practices.`,
    },
  },
};
